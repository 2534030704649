<template>
  <v-card>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 class="text-xs-center">
          <div>Total Video Recordings</div>
          <span class="display-1">
            <v-progress-circular
              v-if="totalCount === null || loading"
              indeterminate
              color="primary"
            />
            <template v-else>{{ totalCount }}</template>
          </span>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const props = {
  report: {
    type: Array,
    default: () => []
  }
}

export default {
  name: 'CameraSummary',
  props,
  data() {
    return {
      loading: false,
      totalCount: null,
      reportType: 'camera_recordings',
      invalidateCache: false
    }
  },
  async mounted() {
    this.$emitter.on('onChange:timerange', this.getNumberOfItems)
    await this.getNumberOfItems()
    this.$emitter.on('updateRecordingsReport', this.updateData)
  },
  beforeDestroy() {
    this.$emitter.off('updateRecordingsReport', this.updateData)
    this.$emitter.off('onChange:timerange', this.getNumberOfItems)
  },
  computed: {
    ...mapGetters('reports', [
      'status',
      'error',
      'range',
      'rfShowWhitelisted',
      'rfShowFiltered'
    ]),
    ...mapGetters('sites', ['activeSite', 'activeSiteId'])
  },
  methods: {
    ...mapActions('reports', ['fetchAnalyticsData']),
    async updateData() {
      this.invalidateCache = true
      await this.getNumberOfItems(this.invalidateCache)
      this.invalidateCache = false
    },
    async getNumberOfItems(invalidate = false) {
      this.loading = true
      const params = {
        start_time: this.range.start_time,
        end_time: this.range.end_time,
        total_count: true,
        type: this.reportType,
        site_id: this.activeSiteId
      }
      const totalCount = await this.fetchAnalyticsData({
        invalidateCache: invalidate,
        format: 'json',
        params: params
      })

      if (!isNaN(totalCount)) {
        this.totalCount = totalCount
        this.$emit('onChange:totalCount', this.totalCount)
      }
      this.loading = false
    }
  },
  watch: {
    activeSiteId: {
      handler: async function() {
        await this.getNumberOfItems()
      }
    }
  }
}
</script>
