import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  name: 'time-chart',
  mixins: [reactiveProp],
  props: {
    options: {
      type: Object,
      default: null
    },
    height: {
      type: Number,
      default: 400
    }
  },
  computed: {
    chartOptions() {
      return {
        ...this.options,
        height: `${this.height}px`
      }
    }
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.chartOptions)
  },
  watch: {
    chartOptions() {
      this.renderChart(this.chartData, this.chartOptions)
    },
    chartData() {
      this.renderChart(this.chartData, this.chartOptions)
    }
  }
}
