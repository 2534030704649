<template>
  <v-card>
    <v-card-text>
      <v-layout row wrap>
        <v-flex>
          <bar-chart
            :data-set="hourlyCount"
            :height="75"
            :options="options('Number of Flights per hour')"
          ></bar-chart>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from '../Charts/BarChart.js'
import moment from 'moment-timezone'

const props = {
  report: {
    type: Object,
    default: () => ({
      by_vendors: [],
      by_durations: [],
      by_frequencies: [],
      by_sites: [],
      events: {}
    })
  }
}

export default {
  name: 'EventHourlyCount',
  props,
  components: { BarChart },
  computed: {
    by_protocols() {
      return this.report.events
        ? Object.entries(
            Object.values(this.report.events).reduce((acc, cur) => {
              return Object.assign(acc, {
                [cur.protocol]: (acc[cur.protocol] || 0) + 1
              })
            }, {})
          )
        : {}
    },
    breakpoints() {
      return this.$vuetify.breakpoint
    },
    events() {
      return this.report.events || []
    },
    localizedEvents() {
      const events = {}
      for (const id in this.report.events) {
        const event = Object.assign({}, this.events[id])
        event.start_time = moment.tz(event.start_time, this.zone)
        event.end_time = moment.tz(event.end_time, this.zone)
        events[id] = event
      }
      return events
    },
    hourlyCount() {
      const eventCount = [...Array(24).keys()].map(hour => 0)
      Object.values(this.localizedEvents).forEach(event => {
        eventCount[event.start_time.hours()] += 1
      })
      return eventCount
    },
    timezone() {
      return moment.tz.guess()
    },
    zone() {
      return moment.tz.zone(this.timezone)
        ? this.timezone
        : this.zones.find(s => s.search(this.timezone) !== -1)
    }
  },
  methods: {
    options(title) {
      return {
        title: {
          text: title,
          fontColor: 'white',
          display: true
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'white'
          }
        }
      }
    },
    dataChart(data) {
      const values = []
      const labels = []
      if (Array.isArray(data)) {
        data.forEach(([l, v]) => {
          labels.push(l)
          values.push(v)
        })
      }
      return {
        values,
        labels
      }
    }
  },
  watch: {
    columnWidths: {
      handler: function(val) {
        this.$emit('onChange:layout', val)
      },
      immediate: true
    }
  }
}
</script>
