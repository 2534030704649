<template>
  <v-card>
    <v-card-text class="fill-height">
      <v-layout row wrap class="fill-height align-center justify-space-between">
        <v-flex :class="`xs${columnWidth}`">
          <!-- <doughnut-chart
                :data-set="dataChart(report && report.by_vendors)"
                :height="200"
                :options="options('Vendors')"
              ></doughnut-chart>-->
          <radar-chart
            :labels="vendors"
            :height="300"
            :data-set="dataChart(report && report.by_vendors)"
            :options="options('Vendors')"
            ref="vendorChart"
            clickableLabels
            @click-chart="$emit('filtersUpdate', filters)"
          />
        </v-flex>
        <v-flex :class="`xs${columnWidth}`">
          <!-- <doughnut-chart
                :data-set="dataChart(report && report.by_durations)"
                :height="200"
                :options="options('Durations')"
              ></doughnut-chart>-->
          <radar-chart
            :labels="['3 mins', '2 mins', '1 min', '> 4 mins', '< 1 min']"
            :height="300"
            :data-set="dataChart(report && report.by_durations)"
            :options="options('Durations')"
            ref="durationChart"
            clickableLabels
            @click-chart="$emit('filtersUpdate', filters)"
          />
        </v-flex>
        <v-flex :class="`xs${columnWidth}`">
          <!-- <doughnut-chart
                :data-set="dataChart(report && report.by_frequencies)"
                :height="200"
                :options="options('Frequencies')"
              ></doughnut-chart>-->
          <radar-chart
            :labels="['2.4 GHz', '2.5 GHz', '5.8 GHz', '433 MHz', '915 MHz']"
            :data-set="dataChart(report && report.by_frequencies)"
            :height="300"
            :options="options('Frequencies')"
            ref="frequencyChart"
            clickableLabels
            @click-chart="$emit('filtersUpdate', filters)"
          />
        </v-flex>
        <v-flex :class="`xs${columnWidth}`">
          <radar-chart
            :labels="protocols"
            :data-set="dataChart(report && report.by_protocols)"
            :height="300"
            :options="options('Protocols')"
            ref="protocolChart"
            clickableLabels
            @click-chart="$emit('filtersUpdate', filters)"
          />
          <!-- <doughnut-chart
                :data-set="dataChart(by_protocols)"
                :height="200"
                :options="options('Protocols')"
              ></doughnut-chart>-->
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import RadarChart from '../Charts/RadarChart'

const props = {
  report: {
    type: Object,
    default: () => ({
      by_vendors: [],
      by_durations: [],
      by_frequencies: [],
      by_protocols: [],
      by_sites: [],
      events: {}
    })
  },
  columnWidth: {
    type: Number,
    default: 3
  },
  vendors: {
    type: Array,
    default: []
  },
  protocols: {
    type: Array,
    default: []
  }
}

export default {
  name: 'EventCharts',
  props,
  components: { RadarChart },
  computed: {
    filters() {
      const charts = [
        'vendorChart',
        'durationChart',
        'frequencyChart',
        'protocolChart'
      ]
      return [].concat(...charts.map(c => this.$refs[c].disabledLabels))
    },
    breakpoints() {
      return this.$vuetify.breakpoint
    },
  },
  methods: {
    options(title) {
      return {
        title: {
          text: title,
          fontColor: 'white',
          display: true
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'white'
          }
        }
      }
    },
    dataChart(data) {
      const values = []
      const labels = []
      if (Array.isArray(data)) {
        data.forEach(([l, v]) => {
          labels.push(l)
          values.push(v)
        })
      }
      return {
        values,
        labels
      }
    }
  }
}
</script>
