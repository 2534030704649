


























































import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseMap from '../BaseMap.vue'
import { LCircleMarker, LFeatureGroup, LPolyline } from 'vue2-leaflet'
import { LatLng } from 'leaflet'
import { IFusionTrackData } from '@/components/Map/EventMaps/types'
import { ISentry } from '@/store/modules/sentries/types'
import FusionDetection from '@/components/Map/Detection/SensorFusion/FusionDetection.vue'
import { isNullOrUndefined } from '@/utils/utils'
import utils from '@/components/Map/utils'
import { TLocationPoint } from '@/components/Map/types'

const props = {
  tracks: {
    type: Array as () => IFusionTrackData[],
    default: () => []
  },
  currentTrack: {
    type: Number,
    default: -1
  },
  protocol: {
    type: String,
    default: ''
  },
  vendor: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: 'eventMap'
  },
  mergedLevel: {
    type: [Number, String],
    default: 0
  },
  sentryLocations: {
    type: Array as () => ISentry[],
    default: () => []
  },
  event: {
    type: Object,
    default: () => {}
  }
}

export default Vue.extend({
  name: 'FusionEventMap',
  components: {
    FusionDetection,
    BaseMap,
    LPolyline,
    LCircleMarker,
    LFeatureGroup
  },
  props,
  data() {
    return {
      center: [0, 0]
    }
  },
  created() {
    this.center = [...this.siteCenter]
  },
  computed: {
    ...mapGetters('sites', ['siteZoom', 'siteCenter']),
    ...mapGetters('sentries', ['sentriesSet']),
    ...mapGetters('maps', ['exporting']),
    ...mapGetters('rf_sensors', ['rfSensorsSet', 'dsxSensorsSet']),
    processedTracks(): IFusionTrackData[] {
      return (
        this.tracks.map(track => {
          const trackData = Object.assign({}, track)
          trackData.DetectionContributions = trackData.detection_contributions
          trackData.sentry = this.findSentryData(track)
          delete trackData.detection_contributions
          return {
            ...trackData
          }
        }) || []
      )
    },
    currentTrackData(): IFusionTrackData {
      if (this.currentTrack === -1) return null
      return this.processedTracks[this.currentTrack]
    },
    latitude(): number {
      if (!this.currentTrackData) return null
      return this.currentTrackData.longitude
    },
    longitude(): number {
      if (!this.currentTrackData) return null
      return this.currentTrackData.longitude
    },
    altitude(): number {
      if (!this.currentTrackData) return null
      return this.currentTrackData.altitude
    },
    timestamp(): number {
      if (!this.currentTrackData) return null
      const timestampString = this.currentTrackData.created_at
      return Math.round(new Date(timestampString).getTime())
    },
    locTracks(): LatLng {
      return this.processedTracks
        .filter(item => {
          return (
            !isNullOrUndefined(item.latitude) &&
            !isNullOrUndefined(item.longitude)
          )
        })
        .map((v: IFusionTrackData) => {
          return { lat: v.latitude, lng: v.longitude, alt: v.altitude }
        })
    },
    isThreat(): boolean {
      return this.event.state !== 'whitelisted'
    }
  },
  methods: {
    ...mapActions('maps', { setExporting: 'SET_EXPORTING' }),
    onReady(): void {
      this.invalidateMap()
      this.centerMapFeatures()
    },
    hasLatLngInfo(tracks: IFusionTrackData[]): boolean {
      return tracks.some(item => !!item.latitude && !!item.longitude)
    },
    centerMapFeatures(): void {
      let bounds = this.$refs['replay-map-feature-group'].mapObject.getBounds()
      if (!bounds.isValid()) {
        const sensors = []
        this.tracks.forEach(track => {
          track.detection_contributions.forEach(contributor => {
            if (
              sensors.findIndex(
                item =>
                  item.id === contributor.sensor_id &&
                  item.type === contributor.sensor_type
              ) === -1
            ) {
              sensors.push({
                id: contributor.sensor_id,
                type: contributor.sensor_type
              })
            }
          })
        })
        const sensorList = sensors.map(item => {
          switch (item.type) {
            case 'rfSensor':
              return this.rfSensorsSet[item.id]
            case 'dsx':
              return this.dsxSensorsSet[item.id]
          }
        })
        let coordinates = []
        sensorList.forEach(sensor => {
          coordinates = coordinates.concat(this.range(sensor))
        })
        bounds = L.latLngBounds(coordinates)
        this.center = [...Object.values(bounds.getCenter())]
      }

      // Map zoom animation to coordinates path
      {
        const mapRef = this.$refs.replayMap.mapObject
        const duration = 1 // Animation duration in seconds
        // Fire viewreset event to redraw map content
        const reRenderFunction = () => mapRef.fire('viewreset')
        mapRef.flyToBounds(bounds, { duration })
        setTimeout(() => {
          mapRef.on('move', reRenderFunction)
          // Remove the reload listener after ANIMATION DURATION seconds
          setTimeout(
            () => mapRef.off('move', reRenderFunction),
            duration * 1000
          )
        }, 100) // Delay before animation starts
      }
    },
    range(sensor): TLocationPoint[] {
      const { reach, direction = 0 } = sensor || {}
      const sentry = this.getSentry(sensor)
      const { latitude = 0, longitude = 0 } = sentry
      const vertex = [latitude, longitude]
      return reach
        ? utils.createRange({ reach, direction, min: -45, max: 45, vertex })
        : []
    },
    getSentry(sensor): ISentry {
      return this.sentriesSet[sensor.sentry_id]
    },
    invalidateMap(): void {
      this.$refs.replayMap.invalidateSize()
    },
    findSentryData(track) {
      const sentryLocations = [...this.sentryLocations]
      if (sentryLocations.length === 0) return null
      if (sentryLocations.length === 1) {
        return { ...sentryLocations[0] }
      }
      const trackCreatedAt = new Date(track?.created_at)
      const differences = sentryLocations.map(loc => {
        const locCreatedAt = new Date(loc.created_at)
        return {
          sentryInfo: loc,
          diff: Math.abs(trackCreatedAt.getTime() - locCreatedAt.getTime())
        }
      })
      const smallestDiff = differences.reduce((res, obj) => {
        return obj.diff < res.diff ? obj : res
      })
      return smallestDiff.sentryInfo
    }
  },
  watch: {
    exporting(v): void {
      //@ts-ignore
      document.querySelector('.leaflet-control-container').style.visibility = v
        ? 'hidden'
        : 'visible'
      this.$nextTick(() => this.invalidateMap())
    }
  }
})
