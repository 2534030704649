

























































































































































































import anime from 'animejs'
import Vue from 'vue'
import {
  IAGLElementStyle,
  IAGLLabelLineProp,
  IAGLLineProps,
  IAGLPoint,
  IAGLRectProps
} from '@/store/modules/agl_mask/types'

const props = {
  background: {
    type: String,
    default: 'grey'
  },
  width: {
    type: Number,
    default: 600
  },
  height: {
    type: Number,
    default: 500
  },
  activeArea: {
    type: Object,
    required: true
  },
  labels: {
    type: Object,
    default: () => {}
  }
}

export default Vue.extend({
  name: 'AGLGraphic',
  props,
  data() {
    return {
      elementsOpacity: 0,
      showMeasurement: false,
      drone: null,
      stageWidth: 0,
      stageHeight: 0,
      upperY: 50,
      radarHeight: 12.52,
      lowerY: this.height - 50,
      droneOffset: {
        x: 0,
        y: 0
      },
      carOffset: {
        x: 0,
        y: 0
      },
      treesOffset: {
        x: 0,
        y: 0
      },
      terrainOffset: {
        x: 0,
        y: 0
      },
      radarOffset: {
        x: 0,
        y: 0
      }
    }
  },
  created(): void {
    this.$emit('ready')
  },
  mounted(): void {
    this.stageWidth = this.width
    this.stageHeight = this.height
    this.$nextTick(() => {
      this.initialize()
    })
  },
  computed: {
    visibleAreaMeasurement(): string {
      return Math.abs(
        Math.max(this.activeArea.value[0], this.activeArea.value[1]) -
          Math.min(this.activeArea.value[0], this.activeArea.value[1])
      ).toFixed(2)
    },
    measurementTextProps(): IAGLPoint {
      return {
        x: 110,
        y: (this.upperLineY + this.lowerLineY) / 2
      }
    },
    measurementTextStyle(): IAGLElementStyle {
      return {
        stroke: 'var(--dro-grey-darken4)'
      }
    },
    measurementLineProps(): IAGLLineProps {
      return {
        x1: 100,
        x2: 100,
        y1: this.upperLineY,
        y2: this.lowerLineY,
        'stroke-dasharray': '4',
        'stroke-width': 3
      }
    },
    measurementLineStyle(): IAGLElementStyle {
      return {
        stroke: 'var(--dro-primary-darken2)'
      }
    },
    backgroundStyle(): IAGLElementStyle {
      return {
        fill: 'var(--dro-primary-lighten2)'
      }
    },
    terrainStyle(): IAGLElementStyle {
      return {
        stroke: 'var(--dro-grey-darken2)',
        fill: 'transparent',
        'stroke-width': 3
      }
    },
    groundLineStyle(): IAGLElementStyle {
      return {
        stroke: 'var(--dro-grey-darken4)',
        'stroke-width': 3
      }
    },
    radarStyle(): IAGLElementStyle {
      return {
        stroke: 'var(--dro-grey-darken4)',
        fill: 'var(--dro-grey-darken4)',
        'stroke-width': 3
      }
    },
    maskLineStyle(): IAGLElementStyle {
      return {
        stroke: 'var(--dro-grey-darken2)',
        'stroke-width': 0
      }
    },
    maskStyle(): IAGLElementStyle {
      return {
        fill: 'var(--dro-grey-darken1)'
      }
    },
    labelStyle(): IAGLElementStyle {
      return {
        fill: 'var(--dro-shades-white)',
        'white-space': 'pre',
        'text-anchor': 'end'
      }
    },
    lineStyle(): IAGLElementStyle {
      return {
        stroke: 'var(--dro-shades-white)',
        'stroke-width': 2
      }
    },
    labelLineProps(): IAGLLabelLineProp[] {
      const props = []
      Object.keys(this.labels).forEach(label => {
        const prop = {}
        const lineProps = {}
        const labelProps = {}

        let adjustedLabel = parseInt(label)
        if (adjustedLabel === 0) {
          adjustedLabel = adjustedLabel + 0.25
        } else if (adjustedLabel === 100) {
          adjustedLabel = adjustedLabel - 0.25
        }

        lineProps['y1'] = this.calculatePercentage(
          this.adjustedHeight,
          adjustedLabel
        )
        lineProps['y2'] = lineProps['y1']
        lineProps['x1'] = 45
        lineProps['x2'] = this.startX
        prop['line'] = lineProps

        labelProps['label'] = this.labels[label]
        labelProps['x'] = 40
        let labelAdjustment = 1
        if (label === '0') {
          labelAdjustment += 1
        }
        if (label === '100') {
          labelAdjustment -= 1
        }
        labelProps['y'] = this.calculatePercentage(
          this.adjustedHeight,
          parseInt(label) + labelAdjustment
        )
        prop['label'] = labelProps
        props.push(prop)
      })
      return props
    },
    adjustedHeight(): number {
      return this.height
    },
    startX(): number {
      return 55
    },
    labelBackgroundProps(): IAGLRectProps {
      return {
        x: 0,
        y: 0,
        width: this.startX,
        height: this.adjustedHeight
      }
    },
    labelBackgroundStyle(): IAGLElementStyle {
      return {
        fill: 'var(--dro-grey-darken3)'
      }
    },
    groundLineProps(): IAGLLineProps {
      return {
        x1: this.startX,
        x2: this.width,
        y1: this.adjustedHeight / 2,
        y2: this.adjustedHeight / 2
      }
    },
    upperMaskProps(): IAGLRectProps {
      return {
        x: this.startX,
        y: 0,
        width: this.width,
        height: this.upperLineY
      }
    },
    lowerMaskProps(): IAGLRectProps {
      return {
        x: this.startX,
        y: this.lowerLineY,
        width: this.width,
        height: this.adjustedHeight - this.lowerLineY
      }
    },
    upperLineY(): number {
      return this.calculatePercentage(
        this.adjustedHeight,
        100 - this.activeArea.percentage[1]
      )
    },
    lowerLineY(): number {
      return this.calculatePercentage(
        this.adjustedHeight,
        100 - this.activeArea.percentage[0]
      )
    },
    radarPosition(): IAGLPoint {
      return {
        x: this.startX,
        y:
          this.calculatePercentage(this.adjustedHeight, 44) - this.radarOffset.y
      }
    },
    terrainPosition(): IAGLPoint {
      return {
        x: this.startX / 2,
        y:
          this.calculatePercentage(this.adjustedHeight, 50) -
          this.terrainOffset.y
      }
    },
    carPosition(): IAGLPoint {
      return {
        x:
          this.startX +
          this.calculatePercentage(this.width, 35) -
          this.carOffset.x,
        y: this.calculatePercentage(this.adjustedHeight, 60) - this.carOffset.y
      }
    },
    treesPosition(): IAGLPoint {
      return {
        x:
          this.startX +
          this.calculatePercentage(this.width, 75) -
          this.treesOffset.x,
        y:
          this.calculatePercentage(this.adjustedHeight, 45) - this.treesOffset.y
      }
    },
    dronesPosition(): IAGLPoint {
      return {
        x:
          this.startX +
          this.calculatePercentage(this.width, 25) -
          this.droneOffset.x,
        y:
          this.calculatePercentage(this.adjustedHeight, 15) - this.droneOffset.y
      }
    }
  },
  methods: {
    initialize() {
      this.$emit('loading', true)
      setTimeout(() => {
        const radarGroup = document.getElementById('agl-radar')
        if (radarGroup) {
          // @ts-ignore
          const radarOffset = radarGroup.getBBox()
          this.radarOffset = {
            x: radarOffset.x,
            y: radarOffset.y
          }
        }
        const dronesGroup = document.getElementById('agl-drones')
        if (dronesGroup) {
          // @ts-ignore
          const droneOffset = dronesGroup.getBBox()
          this.droneOffset = {
            x: droneOffset.x,
            y: droneOffset.y
          }
        }
        const carGroup = document.getElementById('agl-car')
        if (carGroup) {
          // @ts-ignore
          const carOffset = carGroup.getBBox()
          this.carOffset = {
            x: carOffset.x,
            y: carOffset.y
          }
        }
        const treesGroup = document.getElementById('agl-trees')
        if (treesGroup) {
          // @ts-ignore
          const treesOffset = treesGroup.getBBox()
          this.treesOffset = {
            x: treesOffset.x,
            y: treesOffset.y
          }
        }
        const terrainGroup = document.getElementById('agl-terrain')
        if (terrainGroup) {
          // @ts-ignore
          const terrainOffset = terrainGroup.getBBox()
          this.terrainOffset = {
            x: terrainOffset.x,
            y: terrainOffset.y
          }
        }

        anime({
          targets: this,
          elementsOpacity: 1,
          easing: 'easeInOutQuad',
          duration: 300
        })
        this.$emit('loading', false)
      }, 1500)
    },
    calculatePercentage(num: number, percentage: number): number {
      return num * (percentage / 100)
    }
  }
})
