<template>
  <v-card>
    <v-card-text class="fill-height">
      <v-layout class="fill-height align-center">
        <v-flex class="align-self-center">
          <v-progress-circular indeterminate color="primary" />
          <div class="mt-2">{{ text }}</div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
const props = {
  text: {
    type: String,
    default: ''
  }
}

export default {
  name: 'LoadingCard',
  props
}
</script>
