<template>
  <span
    id="timestamp"
    class="mr-4 px-2 grey darken-3 elevation-5"
    style="border-radius: 5px; opacity: 65%"
  >
    {{ currentTime }}
  </span>
</template>

<script>
export default {
  name: 'Timestamp',
  props: {
    currentTime: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.v-dialog--active {
  position: relative;
}
#timestamp {
  display: block;
  position: absolute;
  z-index: 9999;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  top: 70px;
  font-weight: bold;
  left: 70px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  width: 280px;
}
</style>
