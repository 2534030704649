var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-map',{ref:"replayMap",class:{ exporting: _vm.exporting },attrs:{"zoom":_vm.siteZoom,"center":_vm.center,"layerSwitch":!_vm.exporting,"zoomControl":!_vm.exporting,"name":_vm.name,"show-browser-alert":false}},[_c('l-feature-group',{ref:"replay-map-feature-group",on:{"ready":_vm.onReady,"update:visible":_vm.onReady}},[(_vm.tracks.length > 0)?[_c('l-polyline',{attrs:{"opacity":1,"color":"red","fill-opacity":0,"weight":_vm.exporting ? 5 : 2,"lat-lngs":_vm.locTracks}}),_c('l-circle-marker',{attrs:{"radius":_vm.exporting ? 15 : 8,"color":"red","fill-color":"white","fill-opacity":1,"lat-lng":_vm.locTracks[0]}}),_c('l-circle-marker',{attrs:{"radius":_vm.exporting ? 15 : 8,"color":"red","fill-color":"black","fill-opacity":1,"lat-lng":_vm.locTracks[_vm.locTracks.length - 1]}}),(_vm.currentTrack >= 0)?[(_vm.latitude && _vm.longitude)?_c('detection-layer',{attrs:{"visible":!_vm.exporting,"detection":{
            timestamp: _vm.timestamp,
            latitude: _vm.latitude,
            longitude: _vm.longitude,
            altitude: _vm.altitude,
            protocol: _vm.protocol,
            vendor: _vm.vendor,
            classification: 'drone',
            level: _vm.mergedLevel
          },"isReplay":true}}):_vm._e()]:_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }