












import {mapActions, mapState} from "vuex";

const props = {
  event: {
    type: Object,
    default: () => ({
      id: null,
      tracks: [],
      recordings: [],
      event: {}
    })
  },
}

export default {
  name: 'EventCsvExport',
  props,
  data: () => ({
    exportingLocal: false
  }),
  methods: {
    ...mapActions('reports', ['downloadEventData']),
    async exportCsv(): Promise<void> {
      this.exportingLocal = true
      const link = await this.downloadEventData({
        id: this.event.id,
        site_id: this.activeSiteId
      })
      link.click()
      this.exportingLocal = false
    }
  },
  computed: {
    ...mapState('sites', ['activeSiteId']),
  }
}
