<template>
  <video
    ref="videoPlayer"
    class="video-js vjs-theme-sea"
    :controls="controls"
    preload="auto"
    data-setup="{}"
  />
</template>

<script>
import videojs from 'video.js'
import '@/assets/css/video-js.css'
import '@/assets/css/video-js-sea-theme.css'

export default {
  name: 'VideoPlayer',
  props: {
    recording: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: function() {
        return {
          videoOptions: {
            autoplay: true,
            controls: true,
            sources: []
          }
        }
      }
    },
    controls: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    player: null
  }),
  methods: {
    initPlayer() {
      this.player = videojs(this.$refs.videoPlayer, this.options, async () => {
        await this.playRecording()
        this.player.on('waiting', () => this.$emit('waiting'))
        this.player.on('canplaythrough', () => this.$emit('canplaythrough'))
        this.$emit('player', this.player)
      })
    },
    disposePlayer() {
      Object.keys(videojs.getPlayers()).forEach(k => {
        delete videojs.getPlayers()[k]
      })
      this.player.off('waiting')
      this.player.off('canplaythrough')
    },
    async playRecording() {
      if (!this.recording) return
      const url = this.recording.url || []
      this.player.src([
        {
          src: url,
          type: 'video/mp4'
        }
      ])
    }
  },
  mounted() {
    this.initPlayer();
    function forceStylingToVideoPlayer() {
      const videoButton = document.querySelector('.vjs-big-play-button');
      const videoRemainingTime = document.querySelector('.vjs-remaining-time.vjs-time-control.vjs-control');
      const videoProgressHolder = document.querySelector('.vjs-progress-holder');
      const videoControlBar = document.querySelector('.vjs-control-bar');
      videoButton && videoButton.setAttribute('style', '' +
        'width: 103px;' +
        'height: 79px;' +
        'background-color: #ffa726;' +
        'border: none;' +
        'line-height: 79px;' +
        'font-size: 48px;' +
        'top: 50%;' +
        'left: 50%;' +
        'border-radius: 4px;' +
        'margin: -51.5px -39.5px;');
      videoRemainingTime && videoRemainingTime.setAttribute('style', '' +
        'padding: 0 1em;' +
        'margin: 0;' +
        'height: 40px;' +
        'line-height: 40px;');
      videoProgressHolder && videoProgressHolder.setAttribute('style', 'margin: 0;');
      videoControlBar && videoControlBar.setAttribute('style', 'height: 40px');
    }
    forceStylingToVideoPlayer()
  },
  watch: {
    options(v) {
      this.player.src(v.videoOptions.sources)
    },
    async recording() {
      await this.playRecording()
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.disposePlayer()
    }
  }
}
</script>

<style scoped>
.video-js {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}
.video-js .vjs-slider:focus {
  box-shadow: none;
  outline: none;
}
</style>
<style>
.vjs-error-display {
  display: none;
}
</style>
