<template>
  <v-btn
    flat
    @click="takeMapScreenshot"
    :disabled="exportingLocal"
    :loading="exportingLocal"
  >
    Export PDF
    <v-icon right>save</v-icon>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import jsPDF from 'jspdf'
import moment from 'moment-timezone'
require('@/assets/fonts/Exo2-Regular')(jsPDF.API)
require('@/assets/fonts/Exo2-SemiBold')(jsPDF.API)

const props = {
  event: {
    type: Object,
    default: () => ({
      id: null,
      tracks: [],
      recordings: [],
      event: {}
    })
  },
  eventMapName: {
    type: String,
    default: ''
  }
}

export default {
  name: 'EventPdfExport',
  props,
  data: () => ({
    partsList: null,
    exportingLocal: false
  }),
  methods: {
    ...mapActions('maps', { setExporting: 'SET_EXPORTING' }),
    takeMapScreenshot() {
      this.exportingLocal = true
      this.$bus.$emit('screenshotMap', this.eventMapName)
    },
    exportPDF(mapDataURI) {
      const eventObj = this?.event?.event
      const rfDetails = eventObj.frequency_min !== null
      const numOfDetections = eventObj.num_detections
      setTimeout(async () => {
        let pdfName = `detection_report_${this.event.id}`
        let doc = new jsPDF()
        doc.setFont('arial')
        doc.setTextColor(255, 133, 39)
        doc.setFontType('bold')
        doc.text(10, 15, 'Drone Detection Report')
        doc.setFontType('normal')
        doc.text(73, 15, `- ${this.event.site.name}`)

        doc.setFontSize(10)
        doc.setTextColor(0)
        let splitTitle = doc.splitTextToSize(
          `On ${this.date}, DroneShield sensors detected a drone at the "${
            this.event.site.name
          }" site. Sensors were able to track the drone, capturing information on the flight path.`,
          185
        )
        doc.text(10, 24, splitTitle)

        const boxOffset = 0
        doc.setDrawColor(0)
        doc.setFillColor(240)
        doc.rect(
          10 + boxOffset,
          34,
          140,
          rfDetails ? 28 : 22,
          'FD'
        )

        doc.setFontSize(9)
        doc.setFontType('bold')
        doc.text(13 + boxOffset, 39, 'Site Location:')
        if (rfDetails) {
          doc.text(13 + boxOffset, 44, 'Drone Vendor:')
          doc.text(13 + boxOffset, 49, 'Drone Protocol:')
          doc.text(13 + boxOffset, 54, 'Drone Frequency:')
          doc.text(13 + boxOffset, 59, 'Drone Mac Address:')
        }

        doc.setFontType('normal')
        doc.text(44 + boxOffset, 39, this.event.site.name)
        if (rfDetails) {
          doc.text(44 + boxOffset, 44, eventObj.vendor || 'N/A')
          doc.text(44 + boxOffset, 49, this.formatProtocol(this.protocol))
          doc.text(44 + boxOffset, 54, `${this.frequency} MHz`)
          doc.text(44 + boxOffset, 59, `${eventObj?.mac_address || 'N/A'}`)
        }

        doc.setFontType('bold')
        doc.text(85 + boxOffset, 39, 'First Detection:')
        doc.text(85 + boxOffset, 44, 'Last Detection:')
        /* DURATION TITLE */
        doc.text(85 + boxOffset, 49, 'Event Duration:')
        doc.text(85 + boxOffset, 54, 'No. Detections:')

        doc.setFontType('normal')
        /* DURATION VALUE */

        doc.text(
          123 + boxOffset,
          49,
          moment.utc(1000 * eventObj.duration).format('HH:mm:ss')
        )
        doc.text(123 + boxOffset, 54, numOfDetections.toString())
        doc.text(123 + boxOffset, 39, this.formatTime(eventObj.start_time))
        doc.text(123 + boxOffset, 44, this.formatTime(eventObj.end_time))

        const url = require('@/assets/logo_white.jpg')
        const image = await this.loadImage(url)
        const scaleFactor = 0.06
        doc.addImage(
          image,
          'JPEG',
          10,
          283,
          475 * scaleFactor,
          114 * scaleFactor
        )

        let fontSize = doc.internal.getFontSize()
        let pageWidth = doc.internal.pageSize.width
        let txtWidth =
          (doc.getStringUnitWidth('CONFIDENTIAL') * fontSize) /
          doc.internal.scaleFactor

        doc.setTextColor(175)
        doc.setFontSize(12)
        doc.text((pageWidth - txtWidth) / 2, 288, 'CONFIDENTIAL')

        const mapImg = await this.loadImage(mapDataURI)
        //calc img height,
        const scaledWidth = pageWidth - 25
        const scaledHeight = mapImg.height * (scaledWidth / mapImg.width)
        doc.setDrawColor(255, 133, 39)
        doc.setFillColor(255, 133, 39)
        doc.addImage(
          mapImg,
          'PNG',
          11,
          rfDetails ? 74 : 64,
          scaledWidth,
          scaledHeight,
          '',
          'NONE'
        )

        const TOP_OFFSET = 71 - (rfDetails ? -5 : 10)
        const RIGHT_OFFSET = 162
        const BOTTOM_OFFSET = 254 - (rfDetails ? 5 : 10) - 95
        const LEFT_OFFSET = 18

        let startXOffset, startYOffset, endXOffset, endYOffset
        let startRight = this.startLocation[0] > this.endLocation[0]
        startXOffset = startRight ? RIGHT_OFFSET : LEFT_OFFSET
        endXOffset = startRight ? LEFT_OFFSET : RIGHT_OFFSET
        let startTop = this.startLocation[1] > this.endLocation[1]
        startYOffset = startTop ? TOP_OFFSET : BOTTOM_OFFSET
        endYOffset = (startTop ? BOTTOM_OFFSET : TOP_OFFSET) + 5

        doc.setFillColor(1, 1, 1, 0.5)
        doc.rect(startXOffset, startYOffset, 30, 14, 'F')
        doc.setFontSize(9)
        doc.setTextColor(0)
        doc.setFontType('bold')
        doc.text(2 + startXOffset, 4 + startYOffset, 'Start Location')
        doc.text(2 + startXOffset, 8 + startYOffset, 'Lat:')
        doc.text(2 + startXOffset, 12 + startYOffset, 'Long:')
        doc.setFontType('normal')
        doc.text(
          12 + startXOffset,
          8 + startYOffset,
          `${Math.round(this.startLocation[0] * 1e6) / 1e6}`
        )
        doc.text(
          12 + startXOffset,
          12 + startYOffset,
          `${Math.round(this.startLocation[1] * 1e6) / 1e6}`
        )

        doc.setFillColor(1, 1, 1, 0.5)
        doc.rect(endXOffset, endYOffset, 30, 14, 'F')
        doc.setFontSize(9)
        doc.setTextColor(0)
        doc.setFontType('bold')
        doc.text(2 + endXOffset, 4 + endYOffset, 'End Location')
        doc.text(2 + endXOffset, 8 + endYOffset, 'Lat:')
        doc.text(2 + endXOffset, 12 + endYOffset, 'Long:')
        doc.setFontType('normal')
        doc.text(
          12 + endXOffset,
          8 + endYOffset,
          `${Math.round(this.endLocation[0] * 1e6) / 1e6}`
        )
        doc.text(
          12 + endXOffset,
          12 + endYOffset,
          `${Math.round(this.endLocation[1] * 1e6) / 1e6}`
        )

        doc.save(pdfName + '.pdf')
        this.exportingLocal = false
      }, 500)
    },
    formatProtocol(protocol) {
      const mappings = {
        LBv1: 'LightBridge Version 1',
        LBv2: 'LightBridge Version 2'
      }
      return mappings[protocol] || protocol
    },
    formatTime(time) {
      return moment(time)
        .tz(this.event.site.timezone || 'UTC')
        .format('HH:mm:ss.S z')
    },
    loadImage(url) {
      return new Promise(resolve => {
        let img = new Image()
        img.onload = () => resolve(img)
        img.src = url
      })
    }
  },
  watch: {
    async mapScreenshot(img) {
      if (img) {
        //reset screenshot to be blank again
        this.$store.commit('setMapScreenshot', '')

        this.exportPDF(img) //call export pdf function
      }
    }
  },
  computed: {
    ...mapGetters('maps', ['exporting']),
    ...mapState(['mapScreenshot']),

    date() {
      return moment(this.event.event.start_time)
        .tz(this.event.site.timezone || 'UTC')
        .format('MMMM Do, YYYY')
    },
    protocol() {
      return this.event.event.protocol || 'Unknown'
    },
    frequency() {
      return Math.round(this.event.event.frequency_min / 1e5) / 10
    },
    tracks() {
      return this.event.tracks
    },
    trackBounds() {
      const bounds = this.event.tracks.reduce(
        (acc, val) => {
          acc.lat.min = Math.min(acc.lat.min, val.latitude)
          acc.lat.max = Math.max(acc.lat.max, val.latitude)
          acc.lon.min = Math.min(acc.lon.min, val.longitude)
          acc.lon.max = Math.max(acc.lon.max, val.longitude)
          return acc
        },
        { lat: { min: 200, max: -200 }, lon: { min: 200, max: -200 } }
      )
      return [
        [bounds.lat.min, bounds.lon.min],
        [bounds.lat.max, bounds.lon.max]
      ]
    },
    startLocation() {
      return [this.event.tracks[0].latitude, this.event.tracks[0].longitude]
    },
    endLocation() {
      const nTracks = this.event.tracks.length - 1
      return [
        this.event.tracks[nTracks].latitude,
        this.event.tracks[nTracks].longitude
      ]
    }
  }
}
</script>
