<template>
  <v-dialog
    persistent
    :value="dialog === 'start' || dialog === 'end'"
    width="620"
  >
    <v-card v-if="date && date.length === 2" flat>
      <v-card-title>Pick {{ dialog }} date</v-card-title>
      <v-card-text>
        <v-date-picker
          header-color="dark"
          color="primary"
          class="elevation-0"
          v-model="date[0]"
          :min="minMax.min"
          :max="minMax.max"
        />
        <v-time-picker
          header-color="dark"
          color="primary"
          class="elevation-0"
          v-model="date[1]"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="$emit('close')">Cancel</v-btn>
        <v-btn
          color="primary"
          outline
          @click="$emit('onChangeDate', date.join(' '))"
          >Ok</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import moment from "moment-timezone";

import { mapGetters } from 'vuex'

const props = {
  dialog: {
    type: String
  },
  date: {
    type: Array
  }
}

export default {
  name: 'DateRangePicker',
  props,
  computed: {
    ...mapGetters('reports', ['startDate', 'endDate']),
    minMax() {
      if (this.dialog === 'start') {
        return {
          min: undefined,
          max: this.endDate.format('YYYY-MM-DD')
        }
      } else {
        return {
          min: this.startDate.format('YYYY-MM-DD'),
          max: undefined
        }
      }
    }
  }
}
</script>
