<template>
  <v-card>
    <site-logs-dialog
      :event="event"
      :value="dialog"
      @onClickClose="onClickClose"
    />
    <v-card-title><strong>Site Logs Details</strong></v-card-title>
    <v-card-text>
      <v-progress-linear
        height="1"
        indeterminate
        color="primary"
        :class="`${fetchingData ? '' : 'hidden'} mt-0 pt-0`"
      />
      <v-data-table
        :total-items="pagination.totalItems"
        :headers="headers"
        :items="parsedReport"
        :pagination.sync="pagination"
        :rows-per-page-items="pageSizeItems"
        :no-data-text="noDataText"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.whodunnit }}</td>
          <td>{{ props.item.event }}</td>
          <td>{{ props.item.description }}</td>
          <td>{{ props.item.serial_number }}</td>
          <td>{{ props.item.installation }}</td>
          <td>{{ getSiteTime(props.item.created_at) }}</td>
          <td>
            <v-btn
              small
              icon
              @click="onClickEvent(props.item)"
              v-if="props.item.event !== 'delete'"
            >
              <v-icon small>search</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import SiteLogsDialog from './SiteLogsDialog'

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  filters: {
    type: Array,
    default: () => []
  },
  totalCount: {
    type: Number,
    default: 0
  }
}

export default {
  name: 'SiteLogsDetails',
  components: { SiteLogsDialog },
  props,
  data: () => ({
    debounceTimer: null,
    pageSizeItems: [5, 10, 25],
    reportType: 'site_logs',
    fetchingData: false,
    events: [],
    users: [],
    pagination: {
      totalItems: 0,
      page: 1,
      rowsPerPage: 25
    },
    headers: [
      {
        text: 'ID',
        value: 'id',
        align: 'left',
        sortable: false
      },
      {
        text: 'User',
        value: 'whodunnit',
        sortable: false
      },
      {
        text: 'Action',
        value: 'event',
        sortable: false
      },
      {
        text: 'Description',
        value: 'sensor',
        sortable: false
      },
      {
        text: 'Serial Number',
        value: 'serial_number',
        sortable: false
      },
      {
        text: 'Installation',
        value: 'installation',
        sortable: false
      },
      {
        text: 'Timestamp',
        value: 'created_at',
        sortable: false
      },
      {
        text: 'Details',
        value: 'details',
        sortable: false
      }
    ],
    dialog: false,
    event: null
  }),
  computed: {
    ...mapGetters('reports', ['status', 'error', 'range']),
    ...mapGetters('sites', ['activeSiteId']),
    parsedReport() {
      return this.events.map(event => {
        if (event.event === 'destroy') {
          event.event = 'delete'
        }

        if (!event.installation) {
          event.installation = '-'
        }

        if (event.object_changes?.serial_number) {
          event.serial_number = event.object_changes.serial_number[1]
        } else if (event.object?.serial_number) {
          event.serial_number = event.object.serial_number
        } else {
          event.serial_number = '-'
        }

        return event
      })
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    },
    noDataText() {
      return this.fetchingData ? 'Fetching data...' : 'No data available'
    }
  },
  methods: {
    ...mapActions('reports', {
      getEvent: 'FETCH_EVENT'
    }),
    ...mapActions('reports', ['fetchAnalyticsData', 'downloadAnalyticsData']),
    ...mapActions('users', ['FETCH_USERS']),
    getRequestParams(paginated = true) {
      let params = {
        start_time: this.range.start_time,
        end_time: this.range.end_time,
        type: this.reportType,
        site_id: this.activeSiteId,
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage
      }
      if (!paginated) {
        delete params.page
        delete params.per_page
      }
      return params
    },
    async downloadReport() {
      const params = this.getRequestParams(false)
      const downloadedData = await this.downloadAnalyticsData({
        format: 'csv',
        params: params,
        timezone: this.timezone
      })
      downloadedData.click()
    },
    async getTableData() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(async () => {
        this.fetchingData = true
        const params = this.getRequestParams()
        this.users = await this.FETCH_USERS(true)
        this.events = await this.fetchAnalyticsData({
          format: 'json',
          params: params,
          invalidateCache: true
        })
        this.pagination.totalItems = this.totalCount
        this.fetchingData = false
      }, 500)
    },
    getSiteTime(time) {
      return moment.tz(time, this.timezone).format('YYYY-MM-DD HH:mm:ss z')
    },
    async onClickEvent(item) {
      this.event = item
      this.dialog = true
    },
    onClickClose() {
      this.dialog = false
      this.event = null
    }
  },
  async mounted() {
    this.$emitter.on('onChange:timerange', this.getTableData)
    this.$emitter.on('downloadReport', this.downloadReport)
    await this.getTableData()
  },
  beforeDestroy() {
    this.$emitter.off('onChange:timerange', this.getTableData)
    this.$emitter.off('downloadReport', this.downloadReport)
  },
  watch: {
    activeSiteId: {
      handler: async function() {
        this.events = []
        await this.getTableData()
      }
    },
    pagination: {
      handler: async function() {
        await this.getTableData()
      }
    }
  }
}
</script>

<style>
.hidden {
  visibility: hidden;
}
</style>
