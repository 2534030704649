<template>
  <l-feature-group>
    <l-polyline :opacity="1" color="#EEEEEE" :fill-opacity="0" :weight="2" :lat-lngs="l0"></l-polyline>
    <l-circle-marker
      v-for="(p, i) in l1"
      :key="`ri-${i}`"
      :radius="4"
      :stroke="false"
      :opacity="0.5"
      :fill-color="tailColor"
      :fill-opacity="0.5"
      :lat-lng="p"
    />
  </l-feature-group>
</template>

<script>
import { LFeatureGroup, LPolyline, LCircleMarker } from 'vue2-leaflet'
import { EDetectionState } from "@/store/modules/detection/types";

export default {
  name: 'DetectionPolyline',
  props: {
    tail: {
      type: Array,
      default: () => []
    },
    state: {
      type: String
    }
  },
  components: {
    LFeatureGroup,
    LPolyline,
    LCircleMarker
  },
  computed: {
    l0() {
      return this.tail.map(t => t.p)
    },
    l1() {
      return this.tail.filter(t => t.l === 1).map(t => t.p)
    },
    tailColor() {
      if (this.state === EDetectionState.Whitelisted) {
        return 'cyan'
      }
      return 'red'
    }
  },
  methods: {
    radius(level = 0) {
      return level === 1 ? 3 : 1
    },
    fillColor(level = 0) {
      return level === 1 ? 'red' : 'white'
    }
  }
}
</script>
