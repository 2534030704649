<template>
  <div
    :class="
      `video-js vjs-theme-sea vjs-controls-enabled vjs-workinghover vjs-v7 vjs_video_523-dimensions vjs-has-started vjs-active vjs-user-active vjs-playing ${
        hiddenBar ? 'hide' : 'show'
      }`
    "
  >
    <div class="vjs-control-bar" dir="ltr">
      <button
        @click="playing = !playing"
        :class="
          `vjs-play-control vjs-control vjs-button vjs-${
            playing ? 'playing' : 'pause'
          }`
        "
        type="button"
        :title="playing ? 'Pause' : 'Play'"
      >
        <span class="vjs-icon-placeholder"></span>
        <span class="vjs-control-text">{{ playing ? 'Pause' : 'Play' }}</span>
      </button>
      <div class="vjs-time-control vjs-time-divider">
        <div>
          <span>/</span>
        </div>
      </div>
      <div
        class="vjs-progress-control vjs-control"
        ref="progressBar"
        @mousemove="hoverTime"
        @mouseup="mouseUp"
        @mouseleave="mouseUp"
        @mousedown="mouseDown"
      >
        <div
          tabindex="0"
          class="vjs-progress-holder vjs-slider vjs-slider-horizontal"
          role="slider"
        >
          <div
            class="vjs-mouse-display"
            :style="`left: ${hoveredTime.left}px;`"
          >
            <div class="vjs-time-tooltip" style="right: -27px;">
              {{ hoveredTime.time }}
            </div>
          </div>
          <div
            class="vjs-play-progress vjs-slider-bar"
            :style="`width: ${currentTime.progress}%;`"
          >
            <div class="vjs-time-tooltip" style="right: -27px;">
              {{ currentTime.time.format('HH:mm:ss') }}
            </div>
          </div>
          <div
            v-for="(v, i) in videoPercents"
            :key="`ind-vid-${i}`"
            class="videoIndicator"
            :style="`left:${v.left}%;right:${v.right}%`"
          >
            <v-icon color="grey" x-large>videocam</v-icon>
          </div>
        </div>
      </div>
      <div class="vjs-remaining-time vjs-time-control vjs-control">
        <span class="vjs-control-text" role="presentation"
          >Remaining Time&nbsp;</span
        >
        <span>-</span>
        <span class="vjs-remaining-time-display" role="presentation">
          {{ remainingTime }}
        </span>
      </div>
      <div class="vjs-custom-control-spacer vjs-spacer">&nbsp;</div>
      <button
        @click="setFullscren"
        class="vjs-fullscreen-control vjs-control vjs-button"
        type="button"
        title="Fullscreen"
      >
        <span class="vjs-icon-placeholder"></span>
        <span class="vjs-control-text">Fullscreen</span>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ControlBar',
  props: {
    startTime: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 0
    },
    hiddenBar: {
      type: Boolean,
      default: false
    },
    videoPercents: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    playing: false,
    elapsedTime: 0,
    hoveredTime: {
      left: 0,
      progress: 0,
      time: ''
    },
    setTimeInterval: null,
    playerInterval: null
  }),
  methods: {
    setFullscren() {
      this.$emit('fullscreen')
    },
    hoverTime(e) {
      clearTimeout(this.hideBarTimeout)
      const rect = this.$refs.progressBar.getBoundingClientRect()
      const x = e.clientX - rect.left
      const progress = x / rect.width
      this.hoveredTime = {
        left: x,
        progress: progress,
        time: moment
          .unix(this.startTime)
          .add(progress * this.duration, 'seconds')
          .format('HH:mm:ss')
      }
    },
    mouseDown() {
      this.setTimeInterval = setInterval(() => this.clickTime(), 20)
    },
    mouseUp() {
      clearInterval(this.setTimeInterval)
      this.setTimeInterval = null
    },
    clickTime() {
      this.elapsedTime = this.hoveredTime.progress * this.duration
      this.emitCurrentTime()
    },
    emitCurrentTime() {
      this.$emit('timeUpdate', this.currentTime.time.toISOString())
    }
  },
  computed: {
    remainingTime() {
      return moment(
        moment
          .duration(this.duration - this.elapsedTime, 'seconds')
          .asMilliseconds()
      ).format('mm:ss')
    },
    currentTime() {
      return {
        progress: (100 * this.elapsedTime) / this.duration,
        time: moment.unix(this.startTime).add(this.elapsedTime, 'seconds')
      }
    }
  },
  watch: {
    playing(isPlaying) {
      if (isPlaying) {
        if (this.remainingTime === '00:00') {
          this.elapsedTime = 0
        }
        this.playerInterval = setInterval(() => {
          if (this.setTimeInterval) return
          const newTime = this.elapsedTime + 0.1
          if (newTime < this.duration) {
            this.elapsedTime = newTime
          } else {
            this.playing = false
          }
          this.emitCurrentTime()
        }, 100)
        this.$emit('play')
      } else {
        clearInterval(this.playerInterval)
        this.$emit('pause')
      }
    },
    startTime(time) {
      this.$emit('timeUpdate', moment.unix(time).toISOString())
    }
  },
  mounted() {
    this.emitCurrentTime()
  }
}
</script>

<style>
.video-js button.vjs-play-control {
  background-color: rgba(0, 0, 0, 0.6);
}
.video-js .vjs-slider:focus {
  box-shadow: none;
  outline: none;
}
.video-js {
  background-color: rgba(0, 0, 0, 0.9);
}
.video-js .vjs-progress-control .vjs-mouse-display {
  background-color: orange;
}
.hide {
  animation: hide 2s ease-in 2s forwards;
}
@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.show {
  animation: show 0.2s ease-out forwards;
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.videoIndicator {
  display: block;
  position: absolute;
  background-color: white;
  opacity: 0.2;
  height: 100%;
}
</style>
