













































































































































import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { convertValIfImperial, convertUnitIfImperial } from '@/store/utils/index'

interface IRadarMask {
  active: boolean
  azimuth_min: number
  azimuth_max: number
  elevation_min: number
  elevation_max: number
  hard_mask: boolean
  id: number
  is_manual: boolean
  kml: string
  mask_number: number
  name: string
  radar_id: number
  range_min: number
  range_max: number
  rcs_min: number
  rcs_max: number
}

const props = {
  mask: {
    type: Object as () => IRadarMask,
    default: () => ({})
  },
  columns: {
    type: Number,
    default: 2
  }
}

export default Vue.extend({
  name: 'RadarMaskItem',
  props,
  data() {
    return {
      dataFontStyle: 'title',
      deleteMode: false
    }
  },
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    emitEdit(): void {
      this.$emit('onEdit', this.mask)
    },
    emitDelete(): void {
      this.$emit('onDelete', this.mask)
    },
    emitDownload(): void {
      this.$emit('onDownload', this.mask)
    },
    emitToggle(): void {
      this.$emit('onToggle', this.mask)
    }
  },
  computed: {
    ...mapGetters('system', ['systemSetting']),
    ...mapState('sensors', ['status', 'error']),
    columnWidth() {
      return `xs${12 / this.columns}`
    }
  }
})
