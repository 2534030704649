<template>
  <v-dialog :value="status" persistent max-width="600px">
    <v-card>
      <v-card-text>
        <span class="regular" text-color="primary">Loading Report...</span>
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
const props = {
  status: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'LoadingDialog',
  props
}
</script>
