import { Radar } from 'vue-chartjs'
const { helpers } = require('chart.js')

const props = {
  dataSet: {
    type: Object,
    default: () => ({})
  },
  labels: {
    type: Array
  },
  options: {
    type: Object,
    default: () => { }
  },
  clickableLabels: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'radar-chart',
  extends: Radar,
  props,
  data: () => ({
    maxLabelLength: 12,
    disabledLabels: [],
    previouslyHoveredIndex: null
  }),
  computed: {
    parsedLabels() {
      return this.labels || this.dataSet.labels || []
    },
    parsedData() {
      const compaireData = this.parsedLabels.map(e => e.toUpperCase().trim())
      const d = new Array(this.parsedLabels.length).fill(0)
      this.dataSet.labels.forEach((e, i) => {
        const index = compaireData.indexOf(e && e.toUpperCase().trim())
        if (index !== -1) {
          d[index] = this.dataSet.values[i]
        }
      })
      return d
    },
    chartOptions() {
      return {
        ...this.options,
        animation: { duration: 2000, easing: 'easeOutExpo' },
        startAngle: 22,
        scale: {
          gridLines: false,

          angleLines: {
            display: true,
            lineWidth: 0.5,
            color: 'rgba(128, 128, 128, 0.2)'
          },
          pointLabels: {
            fontSize: 14,
            fontStyle: '300',
            fontColor: Array(this.parsedLabels.length).fill('rgba(204, 204, 204, 1)'),
            fontFamily: "'Lato', sans-serif",
            callback: (label) => {
              return label.length > this.maxLabelLength ? label.substr(0, this.maxLabelLength) + '..' : label;
            },
          },
          ticks: {
            beginAtZero: true,
            min: 0,
            display: false
          }
        },
        legend: {
          display: false
        },
        hover: {
          onHover: async (e) => {
            if (this.clickableLabels) {
              const { index, label } = await this.clickedChartLabel(e) || {}
              if (label) {
                e.target.style.cursor = 'pointer';
                if (this.getLabelColor(index) === 'rgba(204, 204, 204, 1)') {
                  this.setLabelColor(index, '#ddd')
                  this.previouslyHoveredIndex = index
                }
              } else if (this.previouslyHoveredIndex != null && this.getLabelColor(this.previouslyHoveredIndex) == '#ddd') {
                e.target.style.cursor = 'default';
                this.setLabelColor(this.previouslyHoveredIndex, 'rgba(204, 204, 204, 1)')
                this.previouslyHoveredIndex = null
              }
            }
          }
        },
        onClick: async (e) => {
          if (this.clickableLabels) {
            const { index, label } = await this.clickedChartLabel(e) || {}
            if (label) {
              const enabledLabel = !this.disabledLabels.includes(label)
              this.setLabelColor(index, enabledLabel ? '#888' : 'rgba(204, 204, 204, 1)')
              enabledLabel ? this.disabledLabels.push(label) : this.disabledLabels.splice(this.disabledLabels.indexOf(label), 1)
            }
            this.$emit('click-chart', this.disabledLabels);
          }
        }
      }
    },
    dataChart() {
      return {
        // labels: Object.keys(this.dataSet),
        labels: this.parsedLabels,
        datasets: [
          {
            data: this.parsedData,
            borderColor: `${this.$vuetify.theme.primary}CC`,
            spanGaps: true,
            lineTension: 0.1,
            backgroundColor: `${this.$vuetify.theme.primary}33`,
            pointBackgroundColor: `${this.$vuetify.theme.primary}33`,
            pointBorderColor: '#fff',
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: `${this.$vuetify.theme.primary}CC`,
          }
        ]
      }
    }
  },
  methods: {
    setLabelColor(labelIndex, color) {
      this.$data._chart.options.scale.pointLabels.fontColor[labelIndex] = color
      if (!this.$data._chart.animating) this.$data._chart.render()
    },
    getLabelColor(labelIndex) {
      return this.$data._chart.options.scale.pointLabels.fontColor[labelIndex]
    },
    async clickedChartLabel(event) {
      let res = null
      const chart = this.$data._chart
      const eventPosition = helpers.getRelativePosition(event, chart.chart);
      const mouseX = eventPosition.x;
      const mouseY = eventPosition.y;

      await helpers.each(chart.scale.ticks, function (label, index) {
        if (res) return
        for (let i = chart.scale.pointLabels.length - 1; i >= 0; i--) {
          const pointLabelPosition = chart.scale.getPointPosition(i, chart.scale.getDistanceFromCenterForValue(chart.scale.options.reverse ? chart.scale.min : chart.scale.max) + 5);
          const pointLabelFontSize = helpers.getValueOrDefault(chart.scale.options.pointLabels.fontSize, Chart.defaults.global.defaultFontSize);
          const pointLabeFontStyle = helpers.getValueOrDefault(chart.scale.options.pointLabels.fontStyle, Chart.defaults.global.defaultFontStyle);
          const pointLabeFontFamily = helpers.getValueOrDefault(chart.scale.options.pointLabels.fontFamily, Chart.defaults.global.defaultFontFamily);
          const pointLabeFont = helpers.fontString(pointLabelFontSize, pointLabeFontStyle, pointLabeFontFamily);
          chart.ctx.font = pointLabeFont;
          const labelsCount = chart.scale.pointLabels.length,
            halfLabelsCount = chart.scale.pointLabels.length / 2,
            quarterLabelsCount = halfLabelsCount / 2,
            upperHalf = (i < quarterLabelsCount || i > labelsCount - quarterLabelsCount),
            exactQuarter = (i === quarterLabelsCount || i === labelsCount - quarterLabelsCount);
          const width = chart.ctx.measureText(chart.scale.pointLabels[i]).width;
          const height = pointLabelFontSize;

          let x, y;
          if (i === 0 || i === halfLabelsCount)
            x = pointLabelPosition.x - width / 2;
          else if (i < halfLabelsCount)
            x = pointLabelPosition.x;
          else
            x = pointLabelPosition.x - width;
          if (exactQuarter)
            y = pointLabelPosition.y - height / 2;
          else if (upperHalf)
            y = pointLabelPosition.y - height;
          else
            y = pointLabelPosition.y
          const margin = 20
          if ((mouseY >= y - margin && mouseY <= y + height + margin) && (mouseX >= x - margin && mouseX <= x + width + margin)) {
            res = { index: i, label: chart.scale.pointLabels[i] }
            break
          }
        }
      }, chart.scale);
      return res
    }
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.dataChart, this.chartOptions)
    this.$data._chart.ctx.canvas.addEventListener('mousedown', function(e){ e.preventDefault(); }, false);
  },
  watch: {
    dataSet() {
      this.renderChart(this.dataChart, this.chartOptions)
    }
  }
}
