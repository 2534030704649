import { render, staticRenderFns } from "./LabelItem.vue?vue&type=template&id=33123bc7&scoped=true&"
import script from "./LabelItem.vue?vue&type=script&lang=ts&"
export * from "./LabelItem.vue?vue&type=script&lang=ts&"
import style0 from "./LabelItem.vue?vue&type=style&index=0&id=33123bc7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33123bc7",
  null
  
)

export default component.exports