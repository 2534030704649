<template>
  <v-dialog max-width="60%" :value="value" persistent>
    <v-toolbar dense flat light color="primary">
      <v-btn icon light @click="onClose">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title
        >Recording {{ recording && recording.filename }}</v-toolbar-title
      >
    </v-toolbar>
    <v-layout row wrap v-if="recording">
      <video-player @player="player = $event" :recording="recording" />
    </v-layout>
    <timestamp :currentTime="currentTime" />
  </v-dialog>
</template>

<script>
const VideoPlayer = () => import('@/components/Widgets/Video/VideoPlayer.vue')
const Timestamp = () => import('@/components/Widgets/Video/Timestamp.vue')
import moment from 'moment'

const props = {
  recording: {
    type: Object,
    default: () => ({
      filename: null,
      url: null
    })
  },
  value: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'DetailRecordingDialog',
  components: { VideoPlayer, Timestamp },
  props,
  data: () => ({
    player: null
  }),
  methods: {
    onClose() {
      this.$emit('onClose')
    }
  },
  computed: {
    startTime() {
      return this.recording
        ? parseInt(moment(this.recording.created_at).format('X'))
        : 0
    },
    currentTime() {
      const elapsed = (this.player && this.player.currentTime()) || 0
      return moment
        .unix(this.startTime)
        .add(elapsed, 'seconds')
        .local()
        .format()
    }
  },
  watch: {
    value: {
      handler: function() {
        window.dispatchEvent(new Event('resize'))
      }
    }
  }
}
</script>
