<template>
  <v-layout>
    <v-dialog v-model="showFullHeatmap" persistent fullscreen>
      <v-card>
        <v-card-text>
          <HeatMap
            height="96vh"
            v-if="showFullHeatmap"
            :coordinates="coordinates"
            :sentries="sentries"
            :site="activeSite"
            @update:zoom="updateZoom"
            @update:center="updateCenter"
            :center="mapCenter"
          >
            <template v-slot:control>
              <v-speed-dial
                open-on-hover
                direction="left"
                transition="slide-x-reverse-transition"
              >
                <v-btn
                  slot="activator"
                  fab
                  small
                  round
                  color="#262626"
                  @click="showFullHeatmap = false"
                >
                  <v-icon>close</v-icon>
                </v-btn>
                <div class="mapLayerLabel">
                  Close Heatmap
                </div>
              </v-speed-dial>
            </template>
          </HeatMap>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-flex>
      <v-card>
        <v-card-text>
          <HeatMap
            :coordinates="coordinates"
            :sentries="sentries"
            :site="activeSite"
            @update:zoom="updateZoom"
            @update:center="updateCenter"
            :center="mapCenter"
          >
            <template v-slot:control>
              <v-speed-dial
                open-on-hover
                direction="left"
                transition="slide-x-reverse-transition"
              >
                <v-btn
                  slot="activator"
                  fab
                  small
                  round
                  color="#262626"
                  @click="showFullMap"
                >
                  <v-icon>
                    {{ showFullHeatmap ? 'fullscreen_exit' : 'fullscreen' }}
                  </v-icon>
                </v-btn>
                <div class="mapLayerLabel">
                  {{ showFullHeatmap ? 'Close Heatmap' : 'Fullscreen Heatmap' }}
                </div>
              </v-speed-dial>
            </template>
          </HeatMap>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import HeatMap from '@/components/Map/HeatMap.vue'
import L from 'leaflet'

const props = {
  events: {
    type: Array,
    default: () => []
  },
  height: {
    type: String,
    default: '35vh'
  }
}

export default {
  name: 'EventHeatMap',
  components: { HeatMap },
  props,
  data() {
    return {
      showFullHeatmap: false,
      renderKey: 0,
      center: null,
      zoom: 12
    }
  },
  mounted() {
    this.center = this.siteCenter
  },
  computed: {
    ...mapGetters('sites', ['activeSite', 'siteCenter']),
    ...mapGetters('sentries', ['sentriesSet']),
    sentries() {
      return Object.values(this.sentriesSet)
    },
    coordinates() {
      const events = []
      this.events.forEach(event => {
        if (event.start_latitude !== null || event.start_longitude !== null) {
          events.push([event.start_latitude, event.start_longitude])
        }
      })
      return events
    },
    mapCenter() {
      if (this.coordinates.length === 0) {
        return this.siteCenter
      } else {
        const bounds = L.latLngBounds(
          this.coordinates.map(coords => {
            return L.latLng(coords[0], coords[1])
          })
        ).getCenter()
        return [bounds.lat, bounds.lng]
      }
    }
  },
  methods: {
    showFullMap() {
      this.showFullHeatmap = !this.showFullHeatmap
      this.renderKey++
    },
    updateZoom(zoom) {
      this.zoom = zoom
      this.renderKey++
    },
    updateCenter(center) {
      this.center = center
      this.renderKey++
    }
  }
}
</script>

<style scoped></style>
