import { Bar } from 'vue-chartjs'

const props = {
  dataSet: {
    type: Array,
    default: () => []
  },
  options: {
    type: Object,
    default: () => {}
  }
}

export default {
  name: 'bar-chart',
  extends: Bar,
  props,
  computed: {
    dataChart() {
      return {
        labels: this.dataSet.map((d, i) => i),
        datasets: [
          {
            data: this.dataSet,
            backgroundColor: `${this.$vuetify.theme.primary}99`
          }
        ]
      }
    }
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.dataChart, {
      ...this.options,
      cutoutPercentage: 60,
      legend: {
        display: false
      }
    })
  },
  watch: {
    dataSet() {
      this.renderChart(this.dataChart, {
        ...this.options,
        cutoutPercentage: 60,
        legend: {
          display: false
        }
      })
    }
  }
}
