<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <SiteLogsSummary @onChange:totalCount="totalCount = $event" />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <SiteLogsDetails :totalCount="totalCount" :timezone="timezone" />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import mixins from '../mixins'
import SiteLogsSummary from "./SiteLogsSummary";
import SiteLogsDetails from "./SiteLogsDetails";

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  meta: {
    type: Object,
    default: () => ({})
  },
  report: {
    type: Array,
    default: () => []
  }
}

export default {
  name: 'SiteLogsReport',
  mixins: [mixins],
  components: { SiteLogsSummary, SiteLogsDetails },
  props,
  data: () => ({
    totalCount: 0,
    date: [],
    dateDialog: null,
    moment,
    filters: []
  }),
  methods: {},
  computed: {
    ...mapGetters('reports', ['reportLink', 'status', 'error']),
    ...mapState('sites', ['activeSiteId']),
  }
}
</script>
