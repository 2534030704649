<template>
  <v-card>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 class="text-xs-center">
          <div>Number of Alerts</div>
          <span class="display-1">
            <v-progress-circular v-if="loading" indeterminate color="primary" />
            <template v-else>{{ totalCount }}</template>
          </span>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const props = {
  report: {
    type: Array,
    default: () => []
  }
}

export default {
  name: 'AlertSummary',
  props,
  data() {
    return {
      loading: false,
      totalCount: null,
      reportType: 'alerts'
    }
  },
  async mounted() {
    await this.getNumberOfAlerts()
  },
  computed: {
    ...mapGetters('reports', [
      'status',
      'error',
      'range',
    ]),
    ...mapGetters('sites', ['activeSite', 'activeSiteId'])
  },
  methods: {
    ...mapActions('reports', ['fetchAnalyticsData']),
    async getNumberOfAlerts() {
      this.loading = true
      const params = {
        start_time: this.range.start_time,
        end_time: this.range.end_time,
        total_count: true,
        type: this.reportType,
        site_id: this.activeSiteId
      }
      const totalCount = await this.fetchAnalyticsData({
        format: 'json',
        params: params
      })

      if (!isNaN(totalCount)) {
        this.totalCount = totalCount
        this.$emit('onChange:totalCount', this.totalCount)
      }
      this.loading = false
    }
  },
  watch: {
    activeSiteId: {
      handler: async function() {
        await this.getNumberOfAlerts()
      }
    }
  }
}
</script>
