<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <DisruptionSummary
          @onChange:totalCount="totalCountUpdated"
          :key="`disruption-summary-${marker}`"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap class="text-xs align-center">
      <v-flex xs12>
        <disruption-details
          :key="`disruption-details-${marker}`"
          :timezone="timezone"
          :totalCount="totalCount"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import DisruptionDetails from './DisruptionDetails'
import DisruptionSummary from './DisruptionSummary'
import moment from 'moment-timezone'

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  meta: {
    type: Object,
    default: () => ({})
  }
}

export default {
  name: 'DisruptionReport',
  components: { DisruptionDetails, DisruptionSummary },
  props,
  data: () => ({
    date: [],
    marker: 0,
    totalCount: 0
  }),
  mounted() {
    this.$emitter.on('onChange:timerange', this.rerender)
  },
  beforeDestroy() {
    this.$emitter.off('onChange:timerange', this.rerender)
  },
  methods: {
    totalCountUpdated(count) {
      this.totalCount = count
    },
    rerender() {
      this.marker++
    }
  }
}
</script>
