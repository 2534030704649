<template>
  <v-card style="min-height:20% !important;padding-top:25px !important">
    <v-card-title>
      <v-switch
        :label="`Mask ${maskFacade.id}`"
        v-model="maskFacade.enabled"
        color="success"
      ></v-switch>
    </v-card-title>
    <v-card-text>
      <v-layout row>
        <v-flex shrink>
          <v-subheader>Range</v-subheader>
        </v-flex>
        <v-flex shrink style="width: 60px">
          <v-text-field
            v-model="range[0]"
            class="mt-0"
            hide-details
            single-line
            type="number"
          ></v-text-field>
          <v-switch
            :input-value="maskFacade.x_min_range !== null"
            :disabled="!maskFacade.enabled"
            @input="onMaskItemEnableChanged(maskFacade, 'x_min_range', $event)"
            color="success"
          ></v-switch>
        </v-flex>
        <v-flex shrink>
          <v-subheader>-</v-subheader>
        </v-flex>
        <v-flex shrink style="width: 60px">
          <v-text-field
            v-model="range[1]"
            class="mt-0"
            hide-details
            single-line
            type="number"
          ></v-text-field>
          <v-switch
            :input-value="maskFacade.x_max_range !== null"
            :disabled="!maskFacade.enabled"
            @input="onMaskItemEnableChanged(maskFacade, 'x_max_range', $event)"
            color="success"
          ></v-switch>
        </v-flex>
        <v-flex class="px-3">
          <v-range-slider v-model="range" :max="6237" :min="0"></v-range-slider>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-text>
      <v-layout row>
        <v-flex shrink>
          <v-subheader>Speed</v-subheader>
        </v-flex>
        <v-flex shrink style="width: 60px">
          <v-text-field
            v-model="speed[0]"
            class="mt-0"
            hide-details
            single-line
            type="number"
          ></v-text-field>
          <v-switch
            :input-value="maskFacade.x_min_speed !== null"
            :disabled="!maskFacade.enabled"
            @input="onMaskItemEnableChanged(maskFacade, 'x_min_speed', $event)"
            color="success"
          ></v-switch>
        </v-flex>
        <v-flex shrink>
          <v-subheader>-</v-subheader>
        </v-flex>
        <v-flex shrink style="width: 60px">
          <v-text-field
            v-model="speed[1]"
            class="mt-0"
            hide-details
            single-line
            type="number"
          ></v-text-field>
          <v-switch
            :input-value="maskFacade.x_max_speed !== null"
            :disabled="!maskFacade.enabled"
            @input="onMaskItemEnableChanged(maskFacade, 'x_max_speed', $event)"
            color="success"
          ></v-switch>
        </v-flex>
        <v-flex class="px-3">
          <v-range-slider v-model="speed" :max="15" :min="-15"></v-range-slider>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import debounce from 'debounce'
export default {
  name: 'RadarRangeMask',
  props: ['radar', 'mask'],
  components: {},
  data: () => ({
    maskFacade: {},
    range: [null, null],
    speed: [null, null]
  }),
  watch: {
    maskFacade: {
      handler: debounce(function(oldValue, newValue) {
        const mask = {
          ...newValue
        }
        mask.x_min_range = this.range[0]
        mask.x_max_range = this.range[1]
        mask.x_min_speed = this.speed[0]
        mask.x_max_speed = this.speed[1]

        return this.onMaskChanged(this.radar, mask)
      }, 500),
      deep: true
    }
  },
  computed: {
    btnColor() {
      return this.mask.enabled ? 'success' : 'red'
    }
  },
  methods: {
    ...mapActions('sensors', {
      updateMask: 'UPDATE_MASK'
    }),
    onMaskEnableChange(state, mask) {
      Object.assign(mask, { enabled: state })
      mask.enabled = state
      const { radar } = this
      this.updateMask({
        radar,
        mask
      })
    },
    /**
     * @param: mask should be a copy (not state mask) with updated info
     */
    onMaskChanged(radar, mask) {
      this.updateMask({
        radar,
        mask
      })
    },
    onMaskItemEnableChanged(mask, prop, state) {
      mask[prop] = state ? 0 : null
      this.onMaskChanged(this.radar, mask)
    }
  },
  mounted() {
    const { mask } = this
    const {
      x_min_range = 0,
      x_max_range = 0,
      x_min_speed = -15,
      x_max_speed = 15
    } = mask || {}

    this.maskFacade = Object.assign({}, mask)

    this.range = [x_min_range, x_max_range]
    this.speed = [x_min_speed, x_max_speed]
  }
}
</script>
