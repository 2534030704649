<template>
  <v-card min-height="300">
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12>
          <time-chart :chartData="chartData" :options="options"></time-chart>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import TimeChart from '../Charts/TimeChart.js'
import { mapGetters } from 'vuex'

const props = {
  report: {
    type: Array,
    default: () => []
  }
}

export default {
  name: 'AlertCharts',
  props,
  data: () => ({}),
  components: { TimeChart },
  computed: {
    ...mapGetters('reports', ['startDate', 'timeframe']),
    options() {
      return {
        title: {
          text: 'Alerts over time',
          fontColor: 'white',
          display: true
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: { unit: 'day' },
              bounds: 'ticks',
              ticks: {
                source: 'data'
              }
            }
          ],
          yAxes: [
            {
              ticks: { min: 0, stepSize: Math.floor(this.report.length * 0.2) }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    },
    dataSet() {
      return {
        values: Object.entries(
          this.report.reduce((acc, cur) => {
            acc[cur.date] = isNaN(acc[cur.date]) ? 1 : acc[cur.date] + 1
            return acc
          }, this.genAcc())
        ).map(([k, v]) => ({ x: k, y: v }))
      }
    },
    chartData() {
      const data = this.dataSet.values.slice(0)
      const dataset = data.sort((a, b) => {
        const a1 = new Date(a.x)
        const b1 = new Date(b.x)
        if (a1 > b1) {
          return -1
        }
        if (a1 > b1) {
          return 1
        }
        return 0
      })
      return {
        datasets: [
          {
            data: dataset,
            borderColor: `${this.$vuetify.theme.primary}CC`,
            pointRadius: 4,
            fill: false,
            lineTension: 0,
            borderWidth: 1
          }
        ]
      }
    }
  },
  methods: {
    genAcc() {
      return new Array(this.timeframe)
        .fill()
        .map((_, i) => {
          const d = this.startDate.clone()
          if (i > 0) d.add(i, 'days')
          return d.format('YYYY-MM-DD')
        })
        .reduce((acc, cur) => ({ ...acc, ...{ [cur]: 0 } }), {})
    }
  },
  watch: {}
}
</script>

<style scoped>
.ts-chart {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
