<template>
  <v-card>
    <event-detail-dialog
      :event="event"
      :value="dialog"
      @onClickClose="onClickClose"
    />
    <alert-dialog
      :alert="alert"
      :value="alertDialog"
      @onClickClose="onClickClose"
    />
    <v-card-title><strong>Alerts Details</strong></v-card-title>
    <v-card-text>
      <v-progress-linear
        height="1"
        indeterminate
        color="primary"
        :class="`${fetchingData ? '' : 'hidden'} mt-0 pt-0`"
      />

      <v-layout row wrap class="ml-4">
        <v-combobox
          ref="combobox"
          label="Search recipients"
          v-model="searchRecipients"
          :items="composedRecipientsList()"
          item-text="label"
          item-value="name"
          multiple
          return-object
          @keyup.enter="getTableData"
          @change="getTableData"
          class="mr-4 custom-wrap"
        />
        <v-text-field
          v-model="searchContent"
          label="Search Content"
          style="max-width: 300px"
          class="mr-4"
          @change="getTableData"
          @keyup="getTableData"
        />
      </v-layout>

      <v-data-table
        :total-items="pagination.totalItems"
        :pagination.sync="pagination"
        :headers="headers"
        :items="alerts"
        :rows-per-page-items="pageSizeItems"
        :no-data-text="noDataText"
        :mustSort="true"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.id }}</td>
          <td>{{ $_getSiteDate(props.item.created_at) }} {{ $_getSiteTime(props.item.created_at) }}</td>
          <td>
            <v-menu
              right
              offset-x
              nudge-right="10"
              :close-on-content-click="false"
              v-if="props.item.recipients.length > 0"
            >
              <template #activator="{ on: menu }">
                <v-chip v-on="{ ...menu }" :color="chipColor">
                  <v-layout row wrap>
                    <v-flex class="align-self-center">
                      <v-avatar class="mr-0 pr-0" :color="avatarColor">
                        {{ props.item.recipients.length }}
                      </v-avatar>
                    </v-flex>
                    <v-flex
                      :class="
                        `align-self-center ${
                          hasType(props.item.recipients, 'email')
                            ? ''
                            : 'order-xs2'
                        }`
                      "
                    >
                      <v-icon
                        :class="
                          hasType(props.item.recipients, 'email')
                            ? ''
                            : 'hidden'
                        "
                      >
                        email
                      </v-icon>
                    </v-flex>
                    <v-flex
                      :class="
                        `align-self-center ${
                          hasType(props.item.recipients, 'sms')
                            ? ''
                            : 'order-xs2'
                        }`
                      "
                    >
                      <v-icon
                        size="22"
                        :class="
                          hasType(props.item.recipients, 'sms')
                            ? ''
                            : 'hidden'
                        "
                      >
                        sms
                      </v-icon>
                    </v-flex>
                  </v-layout>
                </v-chip>
              </template>
              <v-card class="cardStyle" tile>
                <v-list two-line dense>
                  <template
                    v-for="(item, index) in props.item.recipients"
                  >
                    <v-list-tile :key="`name_${props.item.id}_${index}`">
                      <v-list-tile-avatar>
                        <v-icon> {{ item.address_type }}</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                        <v-list-tile-sub-title>{{
                          item.address
                        }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider
                      v-if="
                        index < props.item.recipients.length - 1
                      "
                      :key="`divider_${props.item.id}_${index}`"
                    />
                  </template>
                </v-list>
              </v-card>
            </v-menu>
          </td>
          <td>
            <div
              @click="onClickAlertContent(props.item.content)"
              style="cursor: pointer;"
            >
              <span>{{ props.item.content.substring(0, 150) + '...' }}</span>
            </div>
          </td>
          <td>
            <v-btn
              :data-testid="`magnifying-${props.item.id}`"
              :disabled="invalidEventTypeCheck(props.item.notificatable_type) || outDatedCheck(props.item.created_at)"
              small
              icon
              @click="onClickEvent(props.item.notificatable_id)"
              :loading="loadingEvent && eventLoadingId === props.item.notificatable_id"
            >
              <v-icon small>search</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import EventDetailDialog from '../Event/DetailDialog'
import AlertDialog from './AlertDialog.vue'
import moment from 'moment-timezone'
import mixins from '../mixins'

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  totalCount: {
    type: Number,
    default: 0
  }
}

export default {
  name: 'AlertDetails',
  components: {
    EventDetailDialog,
    AlertDialog
  },
  mixins: [mixins],
  props,
  data: () => ({
    debounceTimer: null,
    fetchingData: false,
    alerts: [],
    pagination: {
      totalItems: 0,
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      descending: true
    },
    pageSizeItems: [5, 10, 25],
    reportType: 'alerts',
    searchContent: null,
    searchRecipients: null,
    chipColor: '#555',
    avatarColor: '#333',
    headers: [
      {
        text: 'ID',
        value: 'id',
        align: 'left',
        sortable: true
      },
      {
        text: 'Date/Time',
        value: 'created_at',
        width: '200px',
        sortable: true
      },
      {
        text: 'Recipients',
        value: 'recipients',
        width: '180px',
        sortable: false
      },
      {
        text: 'Alert',
        value: 'content',
        sortable: false
      },
      {
        text: 'Track',
        value: 'track',
        sortable: false
      },
    ],
    dialog: false,
    alertDialog: false,
    event: null,
    eventLoadingId: null,
    loadingEvent: false,
    alert: null
  }),
  computed: {
    ...mapGetters('reports', [
      'status',
      'error',
      'range',
      'rfShowWhitelisted',
      'rfShowFiltered'
    ]),
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('users', ['recipientsList']),
    noDataText() {
      return this.fetchingData ? 'Fetching data...' : 'No data available'
    }
  },
  methods: {
    ...mapActions('reports', ['fetchAnalyticsData']),
    ...mapActions('reports', { 
      getEvent: 'FETCH_EVENT'
    }),
    async getTableData() {
      clearTimeout(this.debounceTimer)

      this.debounceTimer = setTimeout(async () => {
        this.fetchingData = true
        const params = {
          start_time: this.range.start_time,
          end_time: this.range.end_time,
          type: this.reportType,
          page: this.pagination.page,
          per_page: this.pagination.rowsPerPage,
          site_id: this.activeSiteId,
          ['sort_by_' + this.pagination.sortBy]: this.pagination.descending ? 'DESC' : 'ASC'
        }
        if (this.searchContent) {
          params.filter_by_content = this.searchContent
        }
        if (this.searchRecipients) {
          params.filter_by_recipients = this.searchRecipients.map(recipient => 
            typeof recipient === 'string' ? recipient
              : recipient.address).join(',')
        }
        this.alerts = await this.fetchAnalyticsData({
          format: 'json',
          params: params
        })
        this.pagination.totalItems = await this.fetchAnalyticsData({
          format: 'json',
          params: { total_count: true, ...params }
        })
        this.fetchingData = false
      }, 500)
    },
    composedRecipientsList() {
      return this.recipientsList.map(user => ({ label: user.name + ' ' + user.address, address: user.address }))
    },
    hasType(items, itemType) {
      return !!items.find(item => {
        return item.address_type === itemType
      })
    },
    getSiteTime(time) {
      return moment.tz(time, this.timezone).format('YYYY-MM-DD HH:mm:ss z')
    },
    invalidEventTypeCheck(eventType) {
      // Returns true unless valid eventType
      return eventType !== 'EventSummary'
    },
    outDatedCheck(time) {
      // Returns true if the prop is more than a month old
      var currentTime = moment(moment().format('YYYY-MM-DD HH:mm:ss z'))
      var siteTime = moment(moment(time).format('YYYY-MM-DD HH:mm:ss z'))
      var duration = moment.duration(currentTime.diff(siteTime))
      return duration._milliseconds > 2629800000
    },
    async onClickEvent(eventId) {
      this.loadingEvent = true
      this.eventLoadingId = eventId
      this.event = await this.getEvent(eventId)
      if (this.event.video_recordings) {
        this.event.video_recordings.forEach(async (videoId) => {
          await this.fetchRecording(videoId)
        })
      }
      this.loadingEvent = false
      this.eventLoadingId = null
      this.dialog = true
    },
    onClickAlertContent(content) {
      this.alert = content
      this.alertDialog = true
    },
    onClickClose() {
      this.event = null
      this.dialog = false
      this.alertDialog = false
      this.alert = null
    }
  },
  async mounted() {
    await this.$store.dispatch('users/FETCH_RECIPIENTS', this.activeSiteId)
  },
  watch: {
    totalCount: {
      handler: async function() {
        await this.getTableData()
      }
    },
    activeSiteId: {
      handler: async function() {
        this.alerts = []
        await this.getTableData()
      }
    },
    pagination: {
      handler: async function() {
        await this.getTableData()
      }
    }
  }
}
</script>

<style>
.cardStyle {
  opacity: 0.9;
  border: 1px solid grey !important;
}

.hidden {
  visibility: hidden !important;
}

.custom-wrap .v-select__selections {
  flex-wrap: nowrap;
  white-space: nowrap;
}

</style>
