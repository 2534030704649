









































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import LabelItem from '@/components/EventReport/Camera/LabelItem.vue'
import NewLabelDialog from '@/components/Dialogs/NewLabelDialog.vue'
import {
  IDetectionLabel,
  IDetectionLabelFull
} from '@/store/modules/detection_labels/types'

const props = {
  detection: {
    type: Object,
    default: () => ({})
  }
}

const UNLABELED = 'Unlabeled'

export default Vue.extend({
  name: 'LabelTag',
  components: { NewLabelDialog, LabelItem },
  props,
  data() {
    return {
      tagHovered: false as boolean,
      newLabelName: null as string,
      isThreat: true as boolean,
      showAddLabelDialog: false as boolean,
      openMenu: false as boolean,
      unlabeledString: UNLABELED as string
    }
  },
  computed: {
    ...mapGetters('detection_labels', ['labels', 'formattedLabel']),
    detectionLabels(): IDetectionLabelFull[] {
      return this.detection.labels
    },
    firstDetectionLabel(): IDetectionLabel {
      return this.detectionLabels[0] || null
    },
    classId(): number {
      if (this.firstDetectionLabel?.parent_id) {
        return this.firstDetectionLabel?.parent_id ?? null
      } else {
        return this.firstDetectionLabel?.id ?? null
      }
    },
    subclassId(): number {
      if (this.firstDetectionLabel?.parent_id) {
        return this.firstDetectionLabel?.id ?? null
      } else {
        return null
      }
    },
    labelList(): IDetectionLabel[] {
      return this.labels
    },
    friendlyLabel(): string {
      if (this.detectionLabels.length === 0) return UNLABELED
      return this.firstDetectionLabel.name
    }
  },
  methods: {
    closeAddDialog(): void {
      this.showAddLabelDialog = false
      this.openMenu = false
    },
    clearDetectionLabel(): void {
      this.$emit('labelSelected', null)
    },
    itemSelected(label: IDetectionLabel): void {
      this.openMenu = false
      this.$emit('labelSelected', label)
    }
  }
})
