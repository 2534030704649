<template>
  <div>
    <v-layout row wrap class="text-xs-center align-center">
      <v-flex xs12>
        <alert-summary
          @onChange:totalCount="totalCount = $event"
          :key="`alert-summary-${marker}`"
        />
      </v-flex>
      <v-flex xs12 fill-height>
        <alert-charts v-if="!loading" :report="parsedReport" />
        <LoadingCard
          v-else
          text="Loading Alerts Chart..."
          class="align-self-center"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <alert-details :timezone="timezone" :totalCount="totalCount" />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import AlertCharts from './AlertCharts'
import AlertDetails from './AlertDetails'
import AlertSummary from './AlertSummary'
import { mapState, mapGetters, mapActions } from 'vuex'
import moment from 'moment-timezone'
import mixins from '../mixins'
import LoadingCard from '../LoadingCard'

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  meta: {
    type: Object,
    default: () => ({})
  }
}

export default {
  name: 'AlertReport',
  mixins: [mixins],
  components: { AlertSummary, AlertCharts, AlertDetails, LoadingCard },
  props,
  data: () => ({
    marker: 0,
    reportType: 'alerts',
    totalCount: 0,
    report: [],
    date: [],
    loading: false
  }),
  async mounted() {
    this.$emitter.on('onChange:timerange', this.updateReport)
    this.$emitter.on('downloadReport', this.downloadReport)
    await this.fetchAllData()
  },
  beforeDestroy() {
    this.$emitter.off('onChange:timerange', this.updateReport)
    this.$emitter.off('downloadReport', this.downloadReport)
  },
  computed: {
    ...mapGetters('reports', ['reportLink', 'status', 'error', 'range']),
    ...mapState('sites', ['activeSiteId']),
    parsedReport() {
      return this.report.map(r => {
        return {
          ...r,
          date: this.$_getSiteDate(r.created_at),
          time: this.$_getSiteTime(r.created_at)
        }
      })
    },
    isDisable() {
      return this.report === null || this.status === 'LOADING'
    }
  },
  methods: {
    ...mapActions('reports', ['fetchAnalyticsData', 'downloadAnalyticsData']),
    async downloadReport() {
      const params = this.getRequestParams()
      const downloadedData = await this.downloadAnalyticsData({
        format: 'csv',
        params: params,
        timezone: this.timezone
      })
      downloadedData.click()
    },
    getRequestParams() {
      return {
        start_time: this.range.start_time,
        end_time: this.range.end_time,
        type: this.reportType,
        site_id: this.activeSiteId
      }
    },
    async updateReport() {
      this.report = []
      this.marker++
      await this.fetchAllData()
    },
    async fetchAllData() {
      this.loading = true
      const params = this.getRequestParams()
      this.report = await this.fetchAnalyticsData({
        format: 'json',
        params: params
      })

      this.loading = false
    }
  },
  watch: {
    activeSiteId: {
      handler: async function() {
        await this.updateReport()
      }
    }
  }
}
</script>
