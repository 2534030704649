<template>
  <v-dialog
    @input="$emit('onClickClose')"
    ref="eventDialog"
    width="50%"
    :value="value"
    persistant
    scrollable
  >
    <v-card>
      <v-card-title class="headline sdark">
        <v-layout align-center>
          <v-flex>
          <span class="primary--text text-uppercase font-weight-medium pl-4"
          >Changes</span>
          </v-flex>
          <v-flex shrink>
            <v-btn color="primary" icon flat @click.native="onClickClose">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap align-center>
            <v-data-table
              style="width: 100%;"
              :headers="headers"
              :items="parasedChanges"
              :hide-actions="true"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.attribute }}</td>
                <td>{{ props.item.oldValue }}</td>
                <td>{{ props.item.newValue }}</td>
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

const props = {
  event: {
    type: Object,
    default: () => ({})
  },
  value: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'SiteLogsDialog',
  props,
  data: () => ({
    headers: [
      {
        text: 'Attribute',
        value: 'id',
        align: 'left',
        sortable: false
      },
      {
        text: 'Old Value',
        value: 'old',
        sortable: false
      },
      {
        text: 'New Value',
        value: 'new',
        sortable: false
      }
    ],
  }),
  methods: {
    onClickClose() {
      this.$emit('onClickClose')
    }
  },
  computed: {
    parasedChanges() {
      let parsed = []
      Object.entries(this.event?.object_changes || {}).map(([key, value]) => {
        parsed.push({ attribute: key, oldValue: this.event?.event === "create" ? "" : value[0], newValue: value[1] })
      })
      return parsed
    }
  }
}
</script>
