<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <CameraSummary @onChange:totalCount="totalCount = $event" />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <recording-details
          :recordings="Object.values(recordingsSet)"
          @playRecording="$emit('playRecording', $event)"
          @downloadRecording="$emit('downloadRecording', $event)"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import RecordingDetails from './RecordingDetails'
import CameraSummary from './CameraSummary'

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  meta: {
    type: Object,
    default: () => ({})
  },
  report: {
    type: Array,
    default: () => []
  }
}

export default {
  components: { CameraSummary, RecordingDetails },
  props,
  data: () => ({}),
  computed: {
    ...mapGetters('sensors', ['status', 'recordingsSet'])
  }
}
</script>
