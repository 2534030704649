<template>
  <v-dialog
    @input="$emit('onClickClose')"
    ref="eventDialog"
    width="70%"
    :value="value"
    persistant
  >
    <v-toolbar dense flat light color="primary">
      <v-btn icon light @click="$emit('onClickClose')">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title
        >Event Details ({{
          event && event.event && shortId(event.event.target_id)
        }})</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-toolbar-items color="primary">
        <event-csv-export :event="event" v-if="isFusion" />
      </v-toolbar-items>
      <v-toolbar-items color="primary">
        <event-pdf-export :event="event" eventMapName="eventMapPDF" />
      </v-toolbar-items>
    </v-toolbar>
    <v-layout row wrap fill-height v-if="event">
      <v-flex
        style="max-width: none"
        ref="eventContainer"
        class="container"
        @mouseenter="hiddenBar = false"
        @mouseleave="hiddenBar = true"
      >
        <div
          :style="`--cursor: ${mainDisplay === 'video' ? 'pointer' : 'auto'}`"
          :class="mainDisplay === 'video' ? 'clipped' : 'main'"
          @click.stop="switchMainDisplay('map')"
          ref="map"
        >
          <v-progress-circular
            v-if="exporting"
            id="event-progress"
            size="85"
            :width="3"
            indeterminate
            color="primary"
          />
          <component
            :is="eventMapComponent"
            :id="`${isFusion ? 'fusion' : 'legacy'}-event-map`"
            ref="event-map"
            class="map"
            :tracks="tracks"
            name="eventMapPDF"
            :currentTrack="currentTrack"
            :protocol="protocol"
            :vendor="vendor"
            :mergedLevel="mergedLevel"
            :sentryLocations="sentryLocations"
            :event="event.event"
          />
        </div>
        <div
          v-if="recordings && recordings.length"
          ref="video"
          :style="
            `--bg-color: ${activeVideo ? 'none' : '#222'}; --cursor: ${
              activeVideo ? 'pointer' : 'not-allowed'
            }`
          "
          :class="mainDisplay === 'map' ? 'clipped' : 'main '"
          @click.stop="activeVideo && switchMainDisplay('video')"
        >
          <video-player
            :controls="false"
            :recording="activeVideo"
            @waiting="setMasterState('pause')"
            @canplaythrough="setMasterState('play')"
            @player="player = $event"
          />
        </div>
        <control-bar
          ref="controlBar"
          :hidden-bar="hiddenBar"
          class="control-bar"
          :startTime="startTime"
          :duration="event.event && event.event.duration"
          :videoPercents="videoPercents"
          @timeUpdate="timeUpdate"
          @fullscreen="setFullscreen"
          @play="
            paused = false
            syncPlayPause()
          "
          @pause="
            paused = true
            syncPlayPause()
          "
        />
      </v-flex>
    </v-layout>
    <timestamp :currentTime="currentTimeLocal" />
    <v-fade-transition>
      <EventData
        v-if="isFusion && !!currentTrackData"
        :event="event.event"
        :track="currentTrackData"
        :sensors="sensorsFromTracks"
        :hasRfSensor="hasRfSensor"
        :hasRadarSensor="hasRadarSensor"
        :showLocation="showLocationSection"
      />
    </v-fade-transition>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  VideoPlayer,
  ControlBar,
  Timestamp,
  EventData
} from '@/components/Widgets/Video'
import LegacyEventMap from '../../Map/EventMaps/LegacyEventMap.vue'
import FusionEventMap from '../../Map/EventMaps/FusionEventMap.vue'
import EventPdfExport from './EventPdfExport.vue'
import EventCsvExport from './EventCsvExport.vue'
import moment from 'moment'
import { humanReadableTargetID } from '@/utils/utils'

const props = {
  event: {
    type: Object,
    default: () => ({
      id: null,
      tracks: [],
      event: {},
      site: null
    })
  },
  value: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'EventDetailDialog',
  components: {
    EventData,
    VideoPlayer,
    ControlBar,
    Timestamp,
    EventPdfExport,
    EventCsvExport
  },
  props,
  data: () => ({
    currentTrack: -1,
    mainDisplay: 'map',
    hiddenBar: false,
    currentTime: '',
    paused: true,
    activeVideoId: null,
    resumeTag: false,
    player: null,
    videoPercents: []
  }),
  methods: {
    resetDialog() {
      this.currentTrack = -1
      this.mainDisplay = 'map'
      this.hiddenBar = false
      this.currentTime = ''
      this.paused = true
      this.activeVideoId = null
      this.resumeTag = false
      this.player = null
    },
    initDialog() {
      this.$nextTick(() => {
        const duration = this.endTime - this.startTime
        const percent = recTime => (100 * (recTime - this.startTime)) / duration
        this.videoPercents = this.recordings.map(r => {
          return {
            left: Math.max(0, percent(r.startTime)),
            right: Math.min(
              100,
              100 - percent(r.startTime + r.duration / 1000 + 0.75)
            )
          }
        })
      })
    },
    syncPlayPause() {
      if (!!this.activeVideo && this.paused != this.player.paused()) {
        this.paused ? this.player.pause() : this.player.play()
      }
    },
    syncVideoPlayer() {
      if (this.isPlayerSynced()) return
      const requiredVideoPlayerTime = this.requiredVideoTime()
      if (requiredVideoPlayerTime) {
        this.player.currentTime(requiredVideoPlayerTime)
      }
    },
    switchMainDisplay(caller) {
      if (this.mainDisplay === caller) return
      this.$refs[caller].classList.add('grow')
      let timer = setInterval(() => this.$refs['event-map'].invalidateMap(), 10)
      setTimeout(() => clearInterval(timer), 700)
      setTimeout(() => {
        this.mainDisplay = caller
      }, 500)
    },
    setFullscreen() {
      if (document.fullscreenElement) document.exitFullscreen()
      else this.$refs.eventContainer.requestFullscreen()
    },
    timeUpdate(time) {
      if (!Array.isArray(this.tracks)) return
      this.currentTime = time
      this.currentTrack = this.tracks.findIndex(t => t.created_at <= time)
      this.syncVideoPlayer()
    },
    getMasterPlayerTime() {
      return parseInt(moment(this.currentTime).format('X'))
    },
    isPlayerSynced() {
      if (!this.activeVideo) return false
      const videoPlayerTime =
        this.activeVideo.startTime + this.player.currentTime()
      return Math.abs(this.getMasterPlayerTime() - videoPlayerTime) <= 1
    },
    requiredVideoTime() {
      const masterPlayerTime = this.getMasterPlayerTime()
      const lastActiveVideoId = this.activeVideoId

      let videoPlayerTime
      this.activeVideoId =
        this.recordings &&
        this.recordings.findIndex((recording, i) => {
          this.recordings[i].startTime = parseInt(
            moment(this.recordings[i].created_at).format('X')
          )
          videoPlayerTime = masterPlayerTime - this.recordings[i].startTime
          const videoDuration = this.recordings[i].duration / 1000
          return videoPlayerTime >= 0 && videoPlayerTime <= videoDuration
        })

      if (this.activeVideoId != -1 && lastActiveVideoId != this.activeVideoId) {
        this.syncPlayPause()
      } else if (this.activeVideoId == -1 && lastActiveVideoId != -1) {
        if (!this.paused) this.player.pause()
        if (this.mainDisplay === 'video') this.switchMainDisplay('map')
      }

      return this.activeVideoId != -1 ? videoPlayerTime : null
    },
    setMasterState(action) {
      if (!this.paused && action === 'pause') {
        this.$refs.controlBar.playing = false
        this.resumeTag = true
      } else if (this.paused && this.resumeTag && action === 'play') {
        this.$refs.controlBar.playing = true
        this.resumeTag = false
      }
    },
    shortId(groupId) {
      return humanReadableTargetID(groupId)
    }
  },
  computed: {
    ...mapGetters('maps', ['exporting']),
    ...mapGetters('sensors', ['recordingsSet']),
    ...mapState('sensors', ['radarsSet']),
    ...mapState('rf_sensors', ['rfSensorsSet', 'dsxSensorsSet']),
    sentryLocations() {
      return this.event?.sentry_locations || []
    },
    currentTrackData() {
      if (this.currentTrack == null || this.currentTrack === -1) return null
      return this.tracks[this.currentTrack]
    },
    eventMapComponent() {
      return this.isFusion ? FusionEventMap : LegacyEventMap
    },
    isFusion() {
      return this.event?.event?.detection_type === 'fusion' || false
    },
    protocol() {
      return (this.event?.event && this.event?.event?.protocol) || null
    },
    vendor() {
      return (this.event?.event && this.event?.event?.vendor) || null
    },
    startTime() {
      if (!this.event?.event) return null
      return parseInt(moment(this.event?.event?.start_time).format('X'))
    },
    endTime() {
      if (!this.event?.event) return null
      return parseInt(moment(this.event?.event?.end_time).format('X'))
    },
    activeVideo() {
      return this.activeVideoId === -1 || !this.recordings
        ? null
        : this.recordings[this.activeVideoId]
    },
    tracks() {
      return this.event.tracks
    },
    currentTimeLocal() {
      return moment(this.currentTime)
        .local()
        .format()
    },
    recordings() {
      return (
        this.event?.event?.video_recordings
          .map(r => this.recordingsSet[r])
          .filter(item => item !== undefined) || []
      )
    },
    mergedLevel() {
      let detectionLevel = 0
      if (this.event?.event?.radar_confirmed) detectionLevel = 4
      if (this.event?.event?.rf_confirmed) detectionLevel = 2
      if (this.protocol) detectionLevel = this.protocol.includes('FHSS') ? 3 : 1
      return this.activeVideo ? `${detectionLevel}-5` : detectionLevel
    },
    sensorsFromTracks() {
      const getSensorName = (sensorType, sensorId) => {
        let sensorName = undefined
        switch (sensorType) {
          case 'rfSensor':
            sensorName = this.rfSensorsSet[sensorId]?.name
            break
          case 'dsx':
            sensorName = this.dsxSensorsSet[sensorId]?.name
            break
          case 'radar':
            sensorName = this.radarsSet[sensorId]?.name
            break
          default:
            sensorName = sensorType
        }
        if (sensorName === undefined) sensorName = sensorType
        return sensorName
      }
      // Generate a list of unique sensors from the entire track history
      return this.event.tracks.reduce((accumulator, current) => {
        const contributions = current.detection_contributions
        const newSensors = {}
        for (const index in contributions) {
          const sensorId = contributions[index].sensor_id
          if (!accumulator[sensorId]) {
            newSensors[sensorId] = getSensorName(
              contributions[index].sensor_type,
              sensorId
            )
          }
        }
        return { ...accumulator, ...newSensors }
      }, {})
    },
    showLocationSection() {
      return (
        this.canProduceLocationIntersection ||
        this.event?.event?.latitude !== 0 ||
        this.event?.event?.longitude !== 0
      )
    },
    canProduceLocationIntersection() {
      return this.event.tracks.some(
        ({ detection_contributions: c, latitude, longitude }) => {
          return (
            // has at least 2 rf or 1 radar sensor, or a camera that produced lat/long readings
            c.filter(s => s.sensor_type === 'rfSensor').length > 1 ||
            c.filter(s => s.sensor_type === 'radar').length > 0 ||
            (c.filter(s => s.sensor_type === 'camera').length > 0 &&
              (latitude !== 0 || longitude !== 0))
          )
        }
      )
    },
    hasRfSensor() {
      return this.event.tracks.some(({ detection_contributions: c }) => {
        return (
          // has at least 1 rf sensor
          c.filter(s => s.sensor_type === 'rfSensor' || s.sensor_type === 'dsx')
            .length > 0
        )
      })
    },
    hasRadarSensor() {
      return this.event.tracks.some(({ detection_contributions: c }) => {
        return (
          // has at least 1 radar sensor
          c.filter(s => s.sensor_type === 'radar').length > 0
        )
      })
    }
  },
  watch: {
    value(v) {
      if (!v) this.resetDialog()
      else this.initDialog()
      window.dispatchEvent(new Event('resize'))
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
  background-color: #333;
  height: 0;
  padding: 0;
  padding-top: 56.25%;
  overflow: hidden;
}
.map {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}
.clipped {
  position: absolute;
  width: 40%;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  transition: width 0.5s ease-out;
  animation: 0.5s show ease-out;
}
.main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 500;
}
.grow {
  width: 100%;
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.clipped::before {
  content: '';
  background-color: var(--bg-color);
  transition: background-color 0.2s ease-out;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  cursor: var(--cursor);
}
.control-bar {
  bottom: 0px;
  position: absolute;
  z-index: 9999;
  height: 40px;
  width: 100%;
}
#event-progress {
  display: block;
  width: 100px;
  margin: auto auto;
  top: calc(50% - 45px);
}
</style>
