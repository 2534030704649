<template>
  <v-card>
    <v-card-title v-if="timeAtZone.start && timeAtZone.end">
      <span>{{ title }}</span>
      <v-btn
        flat
        color="primary"
        @click="pickTime('start', timeAtZone.start)"
        >{{ timeAtZone.start.format('YYYY-MM-DD HH:mm z') }}</v-btn
      >
      <span>to</span>
      <v-btn flat color="primary" @click="pickTime('end', timeAtZone.end)">{{
        timeAtZone.end.format('YYYY-MM-DD HH:mm z')
      }}</v-btn>
      <date-range-picker
        :dialog="dateDialog"
        @close="dateDialog = null"
        @onChangeDate="changeDate"
        :date="date"
      />
    </v-card-title>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'
import DateRangePicker from './DateRangePicker.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Timeframe',
  components: { DateRangePicker },
  props: {
    title: {
      type: String,
      default: 'Set report timeframe from'
    },
    timezone: {
      type: String,
      default: 'Etc/UTC'
    }
  },
  data: () => ({
    date: [],
    dateDialog: null,
  }),
  mounted() {
    this.setDefaultTimeFrame()
  },
  computed: {
    ...mapGetters('reports', ['startDate', 'endDate', 'timeFrameLastUpdated']),
    now() {
      return moment.tz(this.timezone)
    },
    timeAtZone() {
      return {
        start: this.startDate.tz(this.timezone),
        end: this.endDate.tz(this.timezone)
      }
    }
  },
  methods: {
    setDefaultTimeFrame() {
      let newDate = this.now
      // if timeframe was last updated more than 1 hour ago, set timeframe to default
      // default is:
      // endTime = current time + 10 minutes
      // startTime = current time - 1 day
      let hoursDiff = moment
        .duration(newDate.diff(this.timeFrameLastUpdated))
        .asHours()
      if (hoursDiff >= 1) {
        newDate.add(10, 'minutes')
        this.pickTime('end', newDate)
        this.changeDate(newDate.format('YYYY-MM-DD HH:mm'))
        newDate.subtract(1, 'day')
        this.pickTime('start', newDate)
        this.changeDate(newDate.format('YYYY-MM-DD HH:mm'))
      }
    },
    changeDate(date) {
      this.$store.commit(
        `reports/SET_${this.dateDialog.toUpperCase()}_DATE`,
        moment.tz(date, this.timezone)
      )

      this.dateDialog = null
      this.$emit('change')
      this.$emitter.emit('onChange:timerange')
    },
    pickTime(type, date) {
      this.date = [date.format('YYYY-MM-DD'), date.format('HH:mm')]
      this.dateDialog = type
    }
  }
}
</script>
