<template>
  <v-card>
    <event-detail-dialog
      :event="event"
      :value="dialog"
      @onClickClose="onClickClose"
    />
    <v-card-title>
      <div class="mb-1"><strong>Detection Event Details</strong></div>
    </v-card-title>
    <v-card-text>

      <v-layout row class="ml-2 search-fields">

        <v-text-field 
          label="Search Group ID"
          v-model="targetIdSearch"
          class="mr-4"
          @blur="getTableData"
          id="targetIdField" />

        <v-select
          :label="`Detections${selectedDetectionTypes.length === 0 ? ': All' : ''}`"
          :items="allDetectionTypes"
          v-model="selectedDetectionTypes"
          @blur="getTableData"
          multiple
          class="mr-4">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              {{ selectedDetectionTypes.length === 0 ? 'All' : selectedDetectionTypes.join(', ') }}
            </span>
          </template>
        </v-select>

        <v-select
          :label="`Vendors${selectedVendors.length === 0 ? ': All' : ''}`"
          :items="vendors"
          v-model="selectedVendors"
          @blur="getTableData"
          multiple
          class="mr-4">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              {{ selectedVendors.length === 0 ? 'All' : selectedVendors.join(', ') }}
            </span>
          </template>
        </v-select>

        <v-select
          :label="`Protocols${selectedProtocols.length === 0 ? ': All' : ''}`"
          :items="protocols"
          v-model="selectedProtocols"
          @blur="getTableData"
          multiple
          class="mr-4">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              {{ selectedProtocols.length === 0 ? 'All' : selectedProtocols.join(', ') }}
            </span>
          </template>
        </v-select>

        <v-select
          :label="`Detection State${selectedDetectionStates.length === 0 ? ': All' : ''}`"
          :items="allDetectionStates"
          v-model="selectedDetectionStates"
          @blur="getTableData"
          multiple
          class="mr-4">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              {{ selectedDetectionStates.length === 0 ? 'All' : selectedDetectionStates.join(', ') }}
            </span>
          </template>
        </v-select>

        <v-select
          :label="`Probability${selectedProbabilities.length === 0 ? ': All' : ''}`"
          :items="allProbabilities"
          v-model="selectedProbabilities"
          @blur="getTableData"
          item-text="label"
          multiple
          class="mr-4">
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              {{ selectedProbabilities.length === 0 ? 'All' : 
                allProbabilities.filter(p => selectedProbabilities.includes(p.value)).map(p => p.label).join(', ') }}
            </span>
          </template>
        </v-select>

      </v-layout>
  
      <v-progress-linear
        height="1"
        indeterminate
        color="primary"
        :class="`${fetchingData ? '' : 'hidden'} mt-0 pt-0`"
      />
      <v-data-table
        :total-items="pagination.totalItems"
        :headers="headers"
        :items="events"
        :pagination.sync="pagination"
        :rows-per-page-items="pageSizeItems"
        :mustSort="true"
      >
        <template slot="items" slot-scope="props">
          <td>{{ shortId(props.item.target_id) }}</td>
          <td>{{ getSiteTime(props.item.start_replay_time || props.item.start_time) }}</td>
          <td>{{ getSiteTime(props.item.end_replay_time || props.item.end_time) }}</td>
          <td>{{ props.item.duration.toFixed(2) }}</td>
          <td>{{ props.item.vendor }}</td>
          <td>{{ props.item.protocol }}</td>
          <td>{{ props.item.num_detections }}</td>
          <td>{{ parseProbability(props.item.probability) }}</td>
          <td class="text-capitalize">{{ props.item.state }}</td>
          <td>
            <v-tooltip right>
              <template #activator="{on}">
                <v-checkbox
                  v-on="on"
                  hide-details
                  color="primary"
                  v-model="props.item.false_positive"
                  @change="falsePositiveClicked(props.item.id, $event)"
                  class="pl-4"
                />
              </template>
              <span>Mark as false positive</span>
            </v-tooltip>
          </td>
          <td>
            <v-btn
              :data-testid="`magnifying-${props.item.id}`"
              :disabled="outDatedCheck(props.item.end_time)"
              small
              icon
              @click="onClickEvent(props.item)"
              :loading="loadingTrack && event.id === props.item.id"
            >
              <v-icon small>search</v-icon>
            </v-btn>
          </td>
          <td v-if="janus" style="white-space: nowrap">
            <v-btn
              v-if="
                !props.item.video_recordings.length ||
                  outDatedCheck(props.item.end_time)
              "
              small
              icon
              disabled
              class="mx-0"
            >
              <v-icon>play_circle_outline</v-icon>
            </v-btn>
            <v-btn
              v-else
              v-for="recordingId in props.item.video_recordings"
              @click="$emit('playRecording', recordingId)"
              :key="`play-${recordingId}`"
              small
              icon
              class="mx-0"
            >
              <v-icon :key="`icon-play-${recordingId}`"
                >play_circle_outline</v-icon
              >
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import EventDetailDialog from './DetailDialog'

import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import { isEqual } from 'lodash'
import { humanReadableTargetID } from '@/utils/utils'

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  filters: {
    type: Array,
    default: () => []
  },
  totalCount: {
    type: Number,
    default: 0
  },
  vendors: {
    type: Array,
    default: () => []
  },
  protocols: {
    type: Array,
    default: () => []
  }
}

export default {
  name: 'EventDetails',
  props,
  components: { EventDetailDialog },
  data: () => ({
    fetchingData: false,
    events: [],
    pagination: {
      totalItems: 0,
      page: 1,
      rowsPerPage: 10,
      sortBy: 'start_time',
      descending: true
    },
    headers: [
      {
        text: 'Group ID',
        value: 'target_id',
        align: 'left',
        sortable: true
      },
      {
        text: 'Start Time',
        value: 'start_time',
        sortable: true
      },
      {
        text: 'End Time',
        value: 'end_time',
        sortable: true
      },
      {
        text: 'Duration (s)',
        value: 'duration',
        align: 'left',
        sortable: false
      },
      {
        text: 'Vendor',
        value: 'vendor',
        sortable: true
      },
      {
        text: 'Protocol',
        value: 'protocol',
        sortable: true
      },
      {
        text: 'Detections Count',
        value: 'num_detections',
        sortable: true
      },
      {
        text: 'Probability',
        value: 'probability',
        align: 'left',
        sortable: true
      },
      {
        text: 'Detection State',
        value: 'state',
        sortable: true
      },
      {
        text: 'False Alarm',
        value: 'false_positive',
        align: 'left',
        sortable: false
      },
      {
        text: 'Track',
        value: 'track',
        sortable: false
      }
    ],
    dialog: false,
    event: null,
    loadingTrack: false,
    pageSizeItems: [5, 10, 25],
    janus: false,
    selectedVendors: [],
    selectedProtocols: [],
    selectedDetectionStates: ['Unfiltered'],
    selectedDetectionTypes: ['True Detections'],
    selectedProbabilities: [],
    targetIdSearch: '',
    tableDataIsDirty: false
  }),
  computed: {
    ...mapGetters('reports', [
      'status',
      'error',
      'range'
    ]),
    ...mapGetters('sites', ['activeSiteId']),
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    },
    allDetectionTypes() {
      return ['True Detections', 'False Alarms']
    },
    allDetectionStates() {
      return ['Whitelisted', 'Unfiltered']
    },
    allProbabilities() {
      return [
        { label: '0-20%', value: 'low' },
        { label: '20-40%', value: 'low-medium' },
        { label: '40-60%', value: 'medium' },
        { label: '60-80%', value: 'medium-high' },
        { label: '80-100%', value: 'high' }
      ]
    }
  },
  methods: {
    ...mapActions('reports', {
      getEvent: 'FETCH_EVENT',
      flagEvent: 'FLAG_EVENT'
    }),
    ...mapActions('reports', [
      'fetchAnalyticsData',
      'updateEventSummary'
    ]),
    ...mapActions('sensors', {
      fetchRecording: 'FETCH_AND_LOAD_RECORDING'
    }),
    async falsePositiveClicked(id, val) {
      const result = await this.updateEventSummary({
        id,
        data: {
          event: {
            false_positive: val
          }
        }
      })
      const index = this.events.findIndex(event => event.id === result.id)
      this.events.splice(index, 1, result)
      this.tableDataIsDirty = true
    },
    async getTableData(e) {
      if (e === undefined || e.type === 'blur') {
        this.fetchingData = true
        this.pagination.totalItems = 0
        const params = {
          start_time: this.range.start_time,
          end_time: this.range.end_time,
          type: 'event_summary',
          page: this.pagination.page,
          per_page: this.pagination.rowsPerPage,
          site_id: this.activeSiteId,
          ['sort_by_' + this.pagination.sortBy]: this.pagination.descending ? 'DESC' : 'ASC',
        }
        if (this.selectedDetectionTypes.length > 0) {
          if (this.selectedDetectionTypes.includes('True Detections') && 
            this.selectedDetectionTypes.includes('False Alarms')) {
              params.filter_by_false_positive = 'true,false'
          }
          else if (this.selectedDetectionTypes.includes('False Alarms')
            && !this.selectedDetectionTypes.includes('True Detections')) {
              params.filter_by_false_positive = 'true'
          } else if (!this.selectedDetectionTypes.includes('False Alarms')
            && this.selectedDetectionTypes.includes('True Detections')) {
            params.filter_by_false_positive = 'false'
          }
        }
        if (this.selectedVendors.length > 0) {
          params.filter_by_vendor = this.selectedVendors.join(',')
        }
        if (this.selectedProtocols.length > 0) {
          params.filter_by_protocol = this.selectedProtocols.join(',')
        }
        if (this.selectedDetectionStates.length > 0) {
          params.filter_by_state = this.selectedDetectionStates.join(',')
        }
        if (this.selectedProbabilities.length > 0) {
          params.filter_by_probability = this.selectedProbabilities.join(',')
        }
        if (this.targetIdSearch.length > 0) {
          params.filter_by_target_id = this.targetIdSearch
        }
        const data = await this.fetchAnalyticsData({
          // if the false positive checkbox state is changed, the axios
          // caches end up being inconsistent with the UI, so conditionally 
          // invalidate the cache
          invalidateCache: this.tableDataIsDirty,
          format: 'json',
          params: params
        })
        this.pagination.totalItems = await this.fetchAnalyticsData({
          invalidateCache: this.tableDataIsDirty,
          format: 'json',
          params: { total_count: true, ...params }
        })
        this.events = data.events
        this.fetchingData = false
        this.tableDataIsDirty = false
      }
    },
    vendorFilter(e) {
      const vendors = ['FPV', 'DJI', 'Yuneec', 'Parrot', 'Xiaomi']
      return (
        e.vendor &&
        vendors.some(
          v =>
            this.filters.includes(v) &&
            v.toUpperCase().includes(e.vendor.toUpperCase())
        )
      )
    },
    protocolFilter(e) {
      const protocols = [
        'Packet',
        'Occusync',
        'Parrot',
        'LBV1',
        'LBV2',
        'Yuneec'
      ]
      return (
        e.protocol &&
        protocols.some(
          p =>
            this.filters.includes(p) &&
            e.protocol.toUpperCase() === p.toUpperCase()
        )
      )
    },
    frequencyFilter(e) {
      return (
        (e.frequency_min &&
          ((this.filters.includes('5.8 GHz') &&
            Math.round(e.frequency_min / 1e8)) === 58 ||
            (this.filters.includes('2.5 GHz') &&
              Math.round(e.frequency_min / 1e8)) === 25 ||
            (this.filters.includes('2.4 GHz') &&
              Math.round(e.frequency_min / 1e8)) === 24)) ||
        (this.filters.includes('915 MHz') &&
          Math.round(e.frequency_min / 1e6)) === 915 ||
        (this.filters.includes('433 MHz') &&
          Math.round(e.frequency_min / 1e6)) === 433
      )
    },
    getSiteTime(time) {
      return moment.tz(time, this.timezone).format('YYYY-MM-DD HH:mm:ss z')
    },
    outDatedCheck(time) {
      // Returns true if the prop is more than a month old
      var currentTime = moment(moment().format('YYYY-MM-DD HH:mm:ss z'))
      var siteTime = moment(moment(time).format('YYYY-MM-DD HH:mm:ss z'))
      var duration = moment.duration(currentTime.diff(siteTime))
      return duration._milliseconds > 2629800000
    },
    async flag(props) {
      const {
        item: { id }
      } = props
      return await this.flagEvent(id)
    },
    async onClickEvent(event) {
      this.loadingTrack = true
      this.event = { id: event.id }
      if (event.video_recordings) {
        event.video_recordings.forEach(async (videoId) => {
          await this.fetchRecording(videoId)
        })
      }
      if (!event.tracks) {
        this.event = await this.getEvent(event.id)
      }
      this.loadingTrack = false
      this.dialog = true
    },
    onClickClose() {
      this.event = null
      this.dialog = false
    },
    onSearchSubmit(e) {
      if (e.key === 'Enter') this.getTableData()
    },
    parseProbability(prob) {
      if (!prob) return "0%"

      return `${(prob * 100).toFixed(2)}%`
    },
    shortId(groupId) {
      return humanReadableTargetID(groupId)
    }
  },
  async mounted() {
    document.getElementById('targetIdField').addEventListener('keypress', this.onSearchSubmit)
    if (this.$janus.isConnected) {
      this.janus = true
      this.headers = [
        ...this.headers,
        {
          text: 'Videos',
          value: 'video',
          sortable: false
        }
      ]
    }
  },
  beforeDestroy() {
    document.getElementById('targetIdField')?.removeEventListener('keypress', this.onSearchSubmit)
  },
  watch: {
    pagination: {
      handler: async function(a, b) {
        if (!isEqual(a, b)) {
          await this.getTableData()
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style scoped>
.hidden {
  visibility: hidden;
}
>>> .v-list__tile__action {
  min-width: auto;
}

[role=combobox] {
  width: calc((100vw - 300px) / 5);
  display: block;
}
.v-select__selections span {
  width: calc(((100vw - 300px) / 5) - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-fields {
  flex-wrap: wrap
}
@media screen and (min-width: 980px) {
  .search-fields {
    flex-wrap: nowrap
  }
}
</style>
