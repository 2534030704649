
















































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { IDetectionLabel } from '@/store/modules/detection_labels/types'

const props = {
  showDialog: {
    type: Boolean,
    default: false
  },
  parentId: {
    type: Number,
    default: null
  },
  detectionId: {
    type: Number,
    required: true
  }
}

export default Vue.extend({
  name: 'NewLabelDialog',
  props,
  data() {
    return {
      error: '' as string,
      formValid: false as boolean,
      newLabelName: null as string,
      isThreat: true as boolean,
      rules: {
        required: value => !!value || 'Required.',
        validChars: value =>
          /^[\sa-zA-Z0-9.-]*$/.test(value) ||
          'Can only have a-z, A-Z, 0-9, -, . '
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs['labelForm'].resetValidation()
      this.$refs['labelName'].focus()
    })
  },
  computed: {
    ...mapGetters('detection_labels', ['labels', 'formattedLabel']),
    parentLabel(): IDetectionLabel {
      return this.labels.find(label => label.id === this.parentId) || null
    }
  },
  methods: {
    ...mapActions('detection_labels', ['addLabel', 'updateLabel']),
    formatLabelName(): void {
      if (!this.newLabelName) {
        return
      }
      const newLabelParts = this.newLabelName.split(' ').map(part => {
        try {
          return part[0].toUpperCase() + part.substr(1)
        } catch {
          return part
        }
      })
      this.newLabelName = this.formattedLabel(newLabelParts.join(' '))
    },
    generateData(item): IDetectionLabel {
      let label
      if (this.parentLabel == null) {
        label = item
      } else {
        label = Object.assign({}, this.parentLabel)
        if (item == null) {
          label.sub_labels = null
        } else {
          label.sub_labels = [item]
        }
      }
      return label
    },
    async onSubmit(): Promise<void> {
      const newLabel = {
        name: this.newLabelName.toLowerCase(),
        threat: this.isThreat,
        parent_id: this.parentId
      }
      const response = await this.addLabel(newLabel)
      if (!response.error) {
        const updateResponse = await this.updateLabel({
          label: this.generateData(newLabel),
          id: this.detectionId
        })
        if (!updateResponse.error) {
          this.$emitter.emit('updateTable')
          this.$emit('closeDialog')
        } else {
          this.error = updateResponse.data
        }
      } else {
        this.error = response.data
      }
    },
    closeDialog(): void {
      this.$emit('closeDialog')
    }
  }
})
