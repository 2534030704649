import moment from 'moment-timezone'

const props = {
  detections: {
    type: Array,
    default: () => []
  },
  timezone: {
    type: String,
    default: moment.tz.guess()
  }
}

export default {
  props,
  data: () => ({
    directions: [
      'NNE',
      'NE',
      'ENE',
      'E',
      'ESE',
      'SE',
      'SSE',
      'S',
      'SSW',
      'SW',
      'WSW',
      'W',
      'WNW',
      'NW',
      'NNW',
      'N'
    ]
  }),
  computed: {},
  methods: {
    $_getSiteDate(time) {
      return moment.tz(time, this.timezone).format('YYYY-MM-DD')
    },
    $_getSiteTime(time) {
      return moment.tz(time, this.timezone).format('HH:mm:ss z')
    },
    $_radToDegree(radians) {
      return (radians * 180) / Math.PI
    },
    $_degreesToDirection(d) {
      try {
        // keep within the range: 0 <= d < 360
        d = d % 360

        if (11.25 <= d && d < 33.75) {
          return 'NNE'
        } else if (33.75 <= d && d < 56.25) {
          return 'NE'
        } else if (56.25 <= d && d < 78.75) {
          return 'ENE'
        } else if (78.75 <= d && d < 101.25) {
          return 'E'
        } else if (101.25 <= d && d < 123.75) {
          return 'ESE'
        } else if (123.75 <= d && d < 146.25) {
          return 'SE'
        } else if (146.25 <= d && d < 168.75) {
          return 'SSE'
        } else if (168.75 <= d && d < 191.25) {
          return 'S'
        } else if (191.25 <= d && d < 213.75) {
          return 'SSW'
        } else if (213.75 <= d && d < 236.25) {
          return 'SW'
        } else if (236.25 <= d && d < 258.75) {
          return 'WSW'
        } else if (258.75 <= d && d < 281.25) {
          return 'W'
        } else if (281.25 <= d && d < 303.75) {
          return 'WNW'
        } else if (303.75 <= d && d < 326.25) {
          return 'NW'
        } else if (326.25 <= d && d < 348.75) {
          return 'NNW'
        } else {
          return 'N'
        }
      } catch(e) {
        console.error(e)
        return 'NaN'
      }
    }
  }
}
