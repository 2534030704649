<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12>
        <event-summary
          :key="`event-summary-${marker}`"
          @onChange:totalCount="totalCount = $event"
          :report="report"
        />
      </v-flex>
    </v-layout>
    <v-layout class="text-xs-center align-center">
      <v-flex fill-height>
        <EventCharts
          v-if="!loading"
          :report="report"
          @filtersUpdate="updateFilters"
          :columnWidth="chartColumnWidth"
          :protocols="allProtocols"
          :vendors="allVendors"
        />
        <LoadingCard
          v-else
          text="Loading Charts..."
          class="align-self-center"
        />
      </v-flex>
    </v-layout>
    <v-layout class="text-xs-center align-center">
      <v-flex fill-height>
        <EventHourlyCount
          v-if="!loading"
          :report="report"
          @filtersUpdate="updateFilters"
        />
        <LoadingCard
          v-else
          text="Loading Hourly Flights Chart..."
          class="align-self-center"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap class="text-xs-center align-center">
      <v-flex xs12>
        <EventHeatMap
          v-if="heatmapEvents.length > 0 || !loading"
          :events="heatmapEvents"
        />
        <LoadingCard v-else text="Loading Heatmap..." />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <event-details
          :key="`event-details-${marker}`"
          :totalCount="totalCount"
          :filters="filters"
          :timezone="timezone"
          :vendors="vendors"
          :protocols="protocols"
          @playRecording="$emit('playRecording', $event)"
        ></event-details>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import EventCharts from './EventCharts'
import EventDetails from './EventDetails'
import EventSummary from './EventSummary'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import EventHeatMap from './EventHeatMap'
import LoadingCard from '../LoadingCard'
import EventHourlyCount from './EventHourlyCount'

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  meta: {
    type: Object,
    default: () => ({})
  },
  chartColumnWidth: {
    type: Number,
    default: 2
  }
}

export default {
  name: 'EventReport',
  components: {
    LoadingCard,
    EventHourlyCount,
    EventHeatMap,
    EventSummary,
    EventCharts,
    EventDetails
  },
  props,
  data: () => ({
    marker: 0,
    report: {
      events: []
    },
    totalCount: 0,
    reportType: 'event_summary',
    date: [],
    filters: [],
    loading: false,
    vendors: [],
    protocols: [],
    allVendors: [],
    allProtocols: []
  }),
  async mounted() {
    this.$emitter.on('onChange:timerange', this.updateReport)
    this.$emitter.on('downloadReport', this.downloadReport)
    await this.fetchAllData()
  },
  beforeDestroy() {
    this.$emitter.off('onChange:timerange', this.updateReport)
    this.$emitter.off('downloadReport', this.downloadReport)
  },
  methods: {
    ...mapActions('reports', ['fetchAnalyticsData', 'downloadAnalyticsData']),
    ...mapActions('sensors', { fetchRfFilterData: 'FETCH_RF_FILTER_DATA' }),
    async downloadReport() {
      const params = this.getRequestParams()
      const downloadedData = await this.downloadAnalyticsData({
        format: 'csv',
        params: params,
        timezone: this.timezone
      })
      downloadedData.click()
    },
    getRequestParams() {
      const params = {
        start_time: this.range.start_time,
        end_time: this.range.end_time,
        type: this.reportType,
        site_id: this.activeSiteId,
      }
      return params
    },
    updateFilters(f) {
      this.filters = f
    },
    async updateReport() {
      this.report = {
        events: []
      }
      this.marker++
      await this.fetchAllData()
    },
    async fetchAllData() {
      this.loading = true
      const params = this.getRequestParams()
      this.report = await this.fetchAnalyticsData({
        format: 'json',
        params: params
      })
      const data = await this.fetchRfFilterData({
        site_id: this.activeSiteId
      })
      // if request fails data will be null
      if (data) {
        this.vendors = data.vendor
        this.protocols = data.protocol
        this.allVendors = [...new Set([...this.vendors, ...this.vendorsFromSensors])]
        this.allProtocols = [...new Set([...this.protocols, ...this.protocolsFromSensors])]
      }
      this.loading = false
    }
  },
  computed: {
    ...mapGetters('reports', [
      'status',
      'range',
      'rfShowWhitelisted',
      'rfShowFiltered',
      'showFalsePositives'
    ]),
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('rf_sensors', {vendorsFromSensors: 'allVendors', protocolsFromSensors: 'allProtocols' }),
    isDisable() {
      return this.report === null || this.status === 'LOADING'
    },
    heatmapEvents() {
      if (this.report && this.report.events) {
        return Object.values(this.report.events)
      } else {
        return []
      }
    }
  },
  watch: {
    activeSiteId: {
      handler: async function() {
        await this.updateReport()
      }
    }
  }
}
</script>
