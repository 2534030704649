<template>
  <BaseMap
    :style="`height: ${height}; width: ${width}`"
    :center="center"
    :zoom="zoom"
    :name="site.name"
    :anchorable="false"
    @update:zoom="updateZoom"
    @update:center="updateCenter"
    :key="`basemap-${renderKey}`"
    :showBrowserAlert="false"
  >
    <template v-slot:additional-controls>
      <l-control position="topright">
        <slot name="control" />
      </l-control>
    </template>
    <l-feature-group
      v-for="sentry in sentries"
      :key="`sentry-${sentry.id}-${renderKey}`"
    >
      <sentry-marker
        :active="false"
        :icon-color="$_getStatusColor(sentry)"
        :draggable="false"
        :sentry="sentry"
      />
    </l-feature-group>
    <site-marker :center="siteCenter" />
    <Vue2LeafletHeatmap :lat-lngs="coordinates" :radius="12" />
  </BaseMap>
</template>

<script>
import BaseMap from '@/components/Map/BaseMap.vue'
import Vue2LeafletHeatmap from '@/components/Map/Vue2LeafletHeatmap.vue'
import { LControl, LFeatureGroup } from 'vue2-leaflet'
import SiteMarker from '@/components/Map/Marker/Site.vue'
import SentryMarker from '@/components/Map/Marker/Sentry.vue'
import { SentryMixin } from '@/components/Mixins'

const props = {
  coordinates: {
    type: Array,
    default: () => []
  },
  sentries: {
    type: Array,
    default: () => []
  },
  site: {
    type: Object,
    default: () => ({})
  },
  renderKey: {
    type: Number,
    default: 0
  },
  height: {
    type: String,
    default: '300px'
  },
  width: {
    type: String,
    default: '100%'
  },
  center: {
    type: Array,
    default: () => [0, 0]
  }
}

export default {
  name: 'HeatMap',
  props,
  mixins: [SentryMixin],
  components: {
    BaseMap,
    SiteMarker,
    SentryMarker,
    LControl,
    LFeatureGroup,
    Vue2LeafletHeatmap
  },
  data() {
    return {
      markerRenderKey: 0,
      mapRenderKey: 0,
      zoom: 10,
      showFullHeatmap: false
    }
  },
  mounted() {
    this.zoom = this.site.zoom_level
  },
  methods: {
    updateZoom(zoom) {
      this.$emit('update:zoom', zoom)
    },
    updateCenter(center) {
      this.$emit('update:center', center)
    }
  },
  computed: {
    siteCenter() {
      return [this.site.latitude, this.site.longitude]
    }
  }
}
</script>

<style scoped></style>
