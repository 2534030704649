




























import Vue from 'vue'
import { DetectionMarker } from '../Marker'
import DetectionPopup from './DetectionPopup.vue'
import DetectionPolyline from './DetectionPolyline.vue'
import arcPath from '@/components/Map/utils/arcPath'
import booleanEqual from '@turf/boolean-equal'
import bearing from '@turf/bearing'
import { point } from '@turf/helpers'
import { LFeatureGroup } from 'vue2-leaflet'
import { mapGetters } from 'vuex'
import {
  ILiveTailItem,
  IRFDetails,
  IDetectionLayerData
} from '@/components/Map/Detection/interfaces'

const props = {
  isReplay: {
    type: Boolean,
    default: false
  },
  visible: {
    type: Boolean,
    default: false
  },
  detection: {
    type: Object,
    default: () => ({
      key: null,
      length: 0,
      points: []
    })
  },
  activeKey: {
    type: [Number, String],
    default: ''
  },
  isCameraDetectingRadar: {
    type: Boolean,
    default: false
  },
  forMaskMap: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'DetectionLayer',
  props,
  provide() {
    return {
      isReplay: this.isReplay
    }
  },
  data: (): IDetectionLayerData => ({
    tail: [],
    timeouts: [],
    direction: 0,
    speed: 0
  }),
  components: {
    DetectionPopup,
    DetectionMarker,
    DetectionPolyline,
    LFeatureGroup
  },
  methods: {
    onSelect(key): void {
      this.$emit('selectDetection', key)
    },
    updateDirection(): void {
      const [ay, ax] = this.tail[0].p
      const { latitude, longitude } = this.detection
      const point1 = point([ax, ay])
      const point2 = point([longitude, latitude])
      let direction = bearing(point1, point2).toFixed()
      if (!booleanEqual(point1, point2))
        this.direction = (360 + parseInt(direction)) % 360
    }
  },
  computed: {
    ...mapGetters('cameras', ['cameraTrackedTarget']),
    theme(): string {
      return this.$store.state.theme
    },
    distance(): string {
      const { latitude, longitude } = this.detection
      const site = this.$store.getters['sites/siteCenter']
      return arcPath
        .earthDistanceInMeters(site[0], site[1], latitude, longitude)
        .toFixed(0)
    },
    eta(): string {
      const { speed } = this.detection
      return (this.distance / speed || 0).toFixed(0)
    },
    rfDetails(): IRFDetails | {} {
      const { rf } = this.detection
      return rf ? { protocol: rf.protocol, vendor: rf.vendor } : {}
    },
    selected(): boolean {
      return this.detection && this.detection.target_id === this.activeKey
    },
    liveTail(): ILiveTailItem[] {
      const { id, latitude, longitude, level } = this.detection
      return [
        {
          id,
          p: [latitude, longitude],
          l: level
        },
        ...this.tail
      ]
    },
    track_tail_length(): number {
      return (this.detection && this.detection.track_tail_length) || 30
    },
    isCameraConfirmed(): boolean {
      return (
        this.isCameraDetectingRadar &&
        this.cameraTrackedTarget == this.detection.target_id
      )
    }
  },
  watch: {
    detection(v): void {
      if (!v) return
      // This reduces the amount of point in the tail for a better display
      // and less charge on the browser
      if (this.tail.length > 0) {
        this.updateDirection()

        const lastPoint = this.tail.find(e => e.l === v.level)

        if (lastPoint) {
          const dist = arcPath.earthDistanceInMeters(
            lastPoint.p[0],
            lastPoint.p[1],
            v.latitude,
            v.longitude
          )

          if (lastPoint.l === v.level && (isNaN(dist) || dist < 10)) return
        }
      }

      this.tail.unshift({
        id: v.id,
        p: [v.latitude, v.longitude],
        l: v.level
      })
      this.timeouts.push(
        setTimeout(() => {
          const index = this.tail.findIndex(e => e.id === v.id)
          this.tail.splice(index, 1)
        }, this.track_tail_length * 1000)
      )
    }
  },
  beforeDestroy(): void {
    // make sure to clear timeout
    for (let i of this.timeouts) {
      clearTimeout(i)
    }
  }
})
