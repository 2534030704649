<template>
  <base-dialog :dialog="dialog" @onClickClose="$_onClickClose">
    <template v-slot:header>
      <v-toolbar-title>Radar Masks</v-toolbar-title>
    </template>
    <template v-slot:default>
      <v-layout row fill-height>
        <v-flex xs12 class="sensorForm px-2">
          <v-flex xs12 class="radar-mask__alerts">
            <browser-alert full-width :default-mode="ESiteMode.Calibration" />
          </v-flex>
          <v-tabs dark slider-color="primary" v-model="active">
            <v-tab v-for="(header, i) in headers" :key="i">{{ header }}</v-tab>
            <v-tab-item :key="0" class="elevation-0">
              <radar-zone-mask :sensor="sensor" :sentry="sentry" />
            </v-tab-item>
            <v-tab-item>
              <v-flex xs12 class="px-5">
                <RadarAboveGroundLevelMask
                  :sensor="sensor"
                  @onClose="$_onClickClose"
                />
              </v-flex>
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </template>
  </base-dialog>
</template>

<script>
import {
  RadarZoneMask,
  RadarAboveGroundLevelMask
} from '@/components/Sentry/SensorGroups/Sensors/RadarMasks'
import { BaseDialog } from '@/components/Base'
import { DialogMixin } from '@/components/Mixins'
import { mapActions } from 'vuex'
import BrowserAlert from "@/components/Base/BrowserAlert.vue";
import { ESiteMode } from '@/store/modules/sites/types'

const props = {
  sensor: {
    type: Object,
    default: () => ({
      zmask: [],
      rmask: []
    })
  },
  sentry: {
    type: Object,
    default: () => ({})
  },
  dialog: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'RadarMaskDialog',
  components: {
    BrowserAlert,
    RadarAboveGroundLevelMask,
    RadarZoneMask,
    BaseDialog
  },
  props,
  mixins: [DialogMixin],
  data: () => ({
    headers: ['ZONE MASK', 'AGL MASK'],
    zmask: null,
    rmask: null,
    rmaskHeaders: [
      {
        text: 'Id',
        value: 'id',
        align: 'left',
        sortable: false
      },
      {
        text: 'Xmax range',
        value: 'x_max_range'
      },
      {
        text: 'Xmin range',
        value: 'x_min_range'
      },
      {
        text: 'Xmax speed',
        value: 'x_max_speed'
      },
      {
        text: 'Xmin speed',
        value: 'x_min_speed'
      }
    ],
    active: null
  }),
  computed: {
    ESiteMode() {
      return ESiteMode
    },
  },
  methods: {
    ...mapActions('sensors', ['CLEAR_STATUS'])
  },

  watch: {
    active() {
      window.dispatchEvent(new Event('resize'))
      // make sure that the save button is not in "done" state
      if (this.active === 1) {
        this.CLEAR_STATUS()
      }
    },
    async dialog() {
      this.active = 0
      // clear status and error
      this.$_dismiss()
    }
  }
}
</script>
<style scoped>
.radar-mask__alerts {
  position: relative;
  padding-top: 4rem;
}
</style>