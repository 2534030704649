

















































import Vue from 'vue'
import veeno from 'noui-vue-fr'
import 'nouislider/dist/nouislider.css'
import { IRawAGLMask } from '@/store/modules/agl_mask/types'
import { mapGetters } from 'vuex'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'

const defaultSliderValue = [-10, 10]
const lowerLimit = -3000
const upperLimit = 3000
const props = {
  values: {
    type: Array,
    default: () => defaultSliderValue
  }
}

export default Vue.extend({
  name: 'AGLSlider',
  props,
  components: {
    Slider: veeno
  },
  data() {
    return {
      formValid: false,
      slider: null,
      sliderBehaviour: 'drag',
      sliderOptions: {
        start: defaultSliderValue
      },
      handlePositions: [],
      sliderValue: defaultSliderValue
    }
  },
  mounted(): void {
    this.sliderValue = Object.values(this.values)
    this.slider.noUiSlider.set(this.sliderValue)
    this.getHandlePositions()
    this.ready()
  },
  computed: {
    ...mapGetters('system', ['systemSetting']),
    formValidValue: {
      get(): boolean {
        return this.formValid
      },
      set(val: boolean): void {
        this.formValid = val
        this.$emit('onValidate', val)
      }
    },
    lowerVal(): number {
      return parseInt(this.sliderValue[0])
    },
    upperVal(): number {
      return parseInt(this.sliderValue[1])
    },
    range(): Object {
      return {
        min: [this.convertValIfImperial(this.systemSetting, lowerLimit, 'm')],
        '20%': [this.convertValIfImperial(this.systemSetting, -300, 'm')],
        '35%': [this.convertValIfImperial(this.systemSetting, -30, 'm')],
        '45%': [this.convertValIfImperial(this.systemSetting, -3, 'm')],
        '50%': [this.convertValIfImperial(this.systemSetting, 0, 'm')],
        '55%': [this.convertValIfImperial(this.systemSetting, 3, 'm')],
        '65%': [this.convertValIfImperial(this.systemSetting, 30, 'm')],
        '80%': [this.convertValIfImperial(this.systemSetting, 300, 'm')],
        max: [this.convertValIfImperial(this.systemSetting, upperLimit, 'm')]
      }
    },
    rules(): Object {
      return {
        required: v => v !== '' || 'Required',
        min: v =>
          v >= this.range.min ||
          `Must be greater or equal to ${this.range.min}`,
        max: v =>
          v <= this.range.max || `Must be less or equal to ${this.range.max}`
      }
    }
  },
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    getSlider(slider): void {
      this.slider = slider
    },
    ready(): void {
      this.$emit('ready', this.range)
    },
    getHandlePositions(): void {
      this.handlePositions = this.$refs[
        'agl-slider'
      ].$el.noUiSlider.getPositions()
      this.$emit('positionUpdated', {
        value: [this.lowerVal, this.upperVal],
        percentage: [
          Math.min(this.handlePositions[0], this.handlePositions[1]),
          Math.max(this.handlePositions[0], this.handlePositions[1])
        ]
      })
    },
    onSliderChange(val): void {
      const values: IRawAGLMask = val.values
      this.sliderValue = [parseFloat(values[0]), parseFloat(values[1])]
      this.getHandlePositions()
    },
    updateLowerVal(v: number): void {
      this.$set(this.sliderValue, 0, v)
      this.$refs['agl-slider'].$el.noUiSlider.set(this.sliderValue)
    },
    updateUpperVal(v: number): void {
      this.$set(this.sliderValue, 1, v)
      this.$refs['agl-slider'].$el.noUiSlider.set(this.sliderValue)
    }
  }
})
