



























































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import BaseMap from '../BaseMap.vue'
import DetectionLayer from '../Detection/DetectionLayer.vue'
import { LPolyline, LCircleMarker, LFeatureGroup } from 'vue2-leaflet'
import { LatLng } from 'leaflet'
import { ILegacyTrackData } from '@/components/Map/EventMaps/types'

const props = {
  tracks: {
    type: Array as () => ILegacyTrackData[],
    default: () => []
  },
  currentTrack: {
    type: Number,
    default: -1
  },
  protocol: {
    type: String,
    default: ''
  },
  vendor: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: 'eventMap'
  },
  mergedLevel: {
    type: [Number, String],
    default: 0
  }
}

export default Vue.extend({
  name: 'LegacyEventMap',
  components: {
    BaseMap,
    DetectionLayer,
    LPolyline,
    LCircleMarker,
    LFeatureGroup
  },
  props,
  data() {
    return {
      center: [0,0]
    }
  },
  created() {
    this.center = [...this.siteCenter]
  },
  computed: {
    ...mapGetters('sites', ['siteZoom', 'siteCenter']),
    ...mapGetters('maps', ['exporting']),
    processedTracks(): ILegacyTrackData[] {
      return this.tracks || []
    },
    currentTrackData(): ILegacyTrackData {
      if (this.currentTrack === -1) return null
      return this.processedTracks[this.currentTrack]
    },
    latitude(): number {
      if (!this.currentTrackData) return null
      return this.currentTrackData.latitude
    },
    longitude(): number {
      if (!this.currentTrackData) return null
      return this.currentTrackData.longitude
    },
    altitude(): number {
      if (!this.currentTrackData) return null
      return this.currentTrackData.altitude
    },
    timestamp(): number {
      if (!this.currentTrackData) return null
      return Math.round(new Date(this.currentTrackData.created_at).getTime())
    },
    locTracks(): LatLng {
      return this.processedTracks.map((v: ILegacyTrackData) => {
        return {
          lat: v.latitude,
          lng: v.longitude,
          alt: v.altitude
        }
      })
    }
  },
  methods: {
    ...mapActions('maps', { setExporting: 'SET_EXPORTING' }),
    onReady(): void {
      this.center = [...this.siteCenter]
      this.invalidateMap()
      this.centerMapFeatures()
    },
    hasLatLngInfo(tracks: ILegacyTrackData[]): boolean {
      return tracks.some((item) => !!item.latitude && !!item.longitude)
    },
    centerMapFeatures(): void {
      const bounds = this.$refs['replay-map-feature-group'].mapObject.getBounds()
      if (Object.keys(bounds).length > 0) {
        this.$refs.replayMap.mapObject.flyToBounds(bounds, {
          duration: 1
        })
      }
    },
    invalidateMap(): void {
      this.$refs.replayMap.invalidateSize()
    },
  },
  watch: {
    exporting(v): void {
      //@ts-ignore
      document.querySelector('.leaflet-control-container').style.visibility = v
        ? 'hidden'
        : 'visible'
      this.$nextTick(() => this.invalidateMap())
    }
  }
})
