<template>
  <v-dialog
    @input="$emit('onClickClose')"
    ref="alertDialog"
    width="50%"
    :value="value"
    persistant
    scrollable
  >
    <v-card>
      <v-card-title class="headline sdark">
        <v-layout align-center>
          <v-flex>
          <span class="primary--text text-uppercase font-weight-medium pl-4"
          >Alert Content</span>
          </v-flex>
          <v-flex shrink>
            <v-btn color="primary" icon flat @click.native="onClickClose">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap align-center>
            <div v-html="parsedAlertDetails" ></div>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

const props = {
  alert: {
    type: String,
    default: ""
  },
  value: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'AlertDialog',
  props,
  methods: {
    onClickClose() {
      this.$emit('onClickClose')
    }
  },
  computed: {
    parsedAlertDetails() {
      if (!this.alert) return ""

      let alert = this.alert
      if (alert.includes("http")) {
        const regexp = new RegExp(/http.*/)
        const match = alert.match(regexp)
        if (match) {
          alert = alert.replace(match[0], '')
          alert += `<a href="${match[0]}" target="_blank">${match[0]}</a>`
        }
      }

      return alert.replaceAll("\n", "<br>")
    }
  }
}
</script>
