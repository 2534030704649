<template>
  <v-card>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs6 class="text-xs-center">
          <div>Number of Flights</div>
          <span class="display-1">
            <v-progress-circular
              v-if="totalCount === null"
              indeterminate
              color="primary"
            />
            <template v-else>{{ totalCount }}</template>
          </span>
        </v-flex>
        <v-flex xs6 class="text-xs-center">
          <div>Average Flight Time</div>
          <template v-if="!loading">
            <span class="display-1">
              {{ (report && report.avg_duration) || 0 }}
            </span>
            min
          </template>
          <v-progress-circular
            v-else
            color="primary"
            indeterminate
            class="mt-2"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const props = {
  report: {
    type: Object,
    default: () => ({
      avg_duration: null,
      avg_altitude: null,
      events: {}
    })
  }
}

export default {
  name: 'EventSummary',
  props,
  data() {
    return {
      totalCount: null,
      reportType: 'event_summary'
    }
  },
  async mounted() {
    await this.getNumberOfFlights()
  },
  computed: {
    ...mapGetters('reports', [
      'status',
      'error',
      'range',
      'radarRfShowWhitelisted',
      'showFalsePositives'
    ]),
    ...mapGetters('sites', ['activeSite', 'activeSiteId']),
    loading() {
      return !Object.keys(this.report).includes('avg_duration')
    }
  },
  methods: {
    ...mapActions('reports', ['fetchAnalyticsData']),
    async getNumberOfFlights() {
      const params = {
        start_time: this.range.start_time,
        end_time: this.range.end_time,
        total_count: true,
        type: this.reportType,
        site_id: this.activeSiteId,
      }

      if (!this.showFalsePositives) {
        delete params.false_positive
      }

      const totalCount = await this.fetchAnalyticsData({
        invalidateCache: false,
        format: 'json',
        params: params
      })

      if (!isNaN(totalCount)) {
        this.totalCount = totalCount
        this.$emit('onChange:totalCount', this.totalCount)
      }
    }
  },
  watch: {
    radarRfShowWhitelisted() {
      this.getNumberOfFlights()
    }
  }
}
</script>
