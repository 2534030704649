



































































import Vue from 'vue'
import NewLabelDialog from '@/components/Dialogs/NewLabelDialog.vue'
import { mapGetters } from 'vuex'

const props = {
  label: {
    type: Object,
    required: true
  },
  labelDetails: {
    type: Object,
    default: () => ({ classId: null, subclassId: null })
  },
  detectionId: {
    type: Number,
    required: true
  }
}

export default Vue.extend({
  name: 'LabelItem',
  components: { NewLabelDialog },
  props,
  data() {
    return {
      showAddLabelDialog: false as boolean
    }
  },
  computed: {
    ...mapGetters('detection_labels', ['formattedLabel'])
  },
  methods: {
    closeAddDialog(): void {
      this.showAddLabelDialog = false
      this.$emit('closeMenu')
    },
    itemClicked(item): void {
      const label = Object.assign({}, this.label)
      if (item == null) {
        label.sub_labels = null
      } else {
        label.sub_labels = label.sub_labels.filter(l => l.id === item.id)
      }
      this.$emit('itemSelected', label)
    }
  }
})
